/* eslint-disable react/prop-types */
// Sample Code

import React from 'react'
import './index.scss' // Assuming you have a CSS file for styling
import AddLogo from 'assets/images/logo.svg'
const AddSection = ({ text, onClick }) => {
  return (
    <label>
      <div className='add-logo-containers'>
        <div className='add-logo'>
          <div className='logo-adjust'>
            <img src={AddLogo} alt='add color' />
            <input type='file' accept='.jpg, .png' onChange={onClick} hidden />
          </div>
          <div className='add-file'>{text}</div>
        </div>
      </div>
    </label>
  )
}

export default AddSection
