import React, { useEffect, useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'

const ForgotPassword = () => {
  let navigate = useNavigate()

  // Form Process
  const [formData, setFormData] = useState({
    resettoken: '',
    password: '',
    confirmPassword: '' // Add confirm password field
  })

  useEffect(() => {
    document.title = 'Password Reset'

    const params = new URLSearchParams(window.location.search)
    const tokenFromParams = params.get('resettoken')

    setToken(tokenFromParams) // Fetch the token first
    console.log(tokenFromParams)
    setFormData((prevFormData) => ({
      ...prevFormData,
      resettoken: tokenFromParams, // Now use the fetched token
      password: '',
      confirmPassword: ''
    }))
  }, [])

  const [errors, setErrors] = useState({})
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Prevent multiple submissions
    if (loading) {
      return
    }

    const validationErrors = validateForm(formData)

    if (Object.keys(validationErrors).length === 0) {
      try {
        setLoading(true) // Start loader

        const apiUrl = process.env.REACT_APP_API_URL + '/api/password/reset'
        const headers = {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY
        }

        await axios.post(apiUrl, formData, { headers: headers })

        setFormData({
          resettoken: token,
          password: '',
          confirmPassword: ''
        })
        setErrors({})

        toast.success('Password reset successfully')
        navigate(`/login`)
      } catch (error) {
        console.error('Error:', error)
        toast.error('Error resetting password')
      } finally {
        setLoading(false) // Stop loader
      }
    } else {
      setErrors(validationErrors)
    }
  }

  const validateForm = (data) => {
    const errors = {}

    if (!data.password.trim()) {
      errors.password = 'Password is required'
    }

    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match'
    }

    return errors
  }

  return (
    <div className='container'>
      <div className='container1'>
        <div className='container-left'>
          <h1 className='welcome'>Welcome</h1>
          <div className='first-line'>Thank you for registering with the 40 Knots AutonoME platform.</div>
          <p className='para'>
            If you’re on this page, you’ve spoken with one of our representatives and have been provided a temporary username and password.
          </p>
          <p className='para'>Please create a new, unique username and password.</p>
        </div>
        <div className='container-right'>
          <form className='user-form' onSubmit={handleSubmit}>
            <p className='heading2'>Create a unique password</p>

            <div className='input-box'>
              <input type='password' name='password' placeholder='New password' value={formData.password} onChange={handleInputChange} />
              {errors.password && <span className='error-color'>{errors.password}</span>}
            </div>

            <div className='input-box'>
              <input
                type='password'
                name='confirmPassword'
                placeholder='Confirm password'
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              {errors.confirmPassword && <span className='error-color'>{errors.confirmPassword}</span>}
            </div>

            <button className='register-button' disabled={loading}>
              {loading ? 'Changing Password...' : 'Change Password'}
            </button>
          </form>
        </div>
        {/* Sign in form */}
        <div>
          <div className='container3'>
            <span className='terms'>
              <p>40 Knots™ 888 Prospect Street Suite 200 La Jolla, California 92037 USA</p>
              <p>
                <b>Terms of Use | Privacy Policy | Cookie Policy</b>
              </p>
              <p>© 2023 Corsaire Corporation™. All rights reserved.</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
