import { headers, getUserIdFromLocalStorage } from 'config/apiConfig'
import axios from 'axios'

export const fetchFonts = async () => {
  let res = null
  let err = null

  try {
    const userId = getUserIdFromLocalStorage()
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/select/${userId}/fonts`
    const response = await axios.get(apiUrl, { headers })
    res = response
  } catch (error) {
    err = error
  }
  return {
    response: res,
    error: err
  }
}

export const insertFonts = async (datafonts) => {
  let res = null
  let err = null
  try {
    const apiUrl = process.env.REACT_APP_API_URL + '/api/insert/fonts'
    const response = await axios.post(apiUrl, datafonts, { headers })
    res = response
  } catch (error) {
    err = error
    return err
  }
  return res
}
