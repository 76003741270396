import React, { useState } from 'react'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { handlePdfUpload } from 'helper/apiHelper'
import { getUserIdFromLocalStorage } from 'config/apiConfig'
import './index.scss'

const ReferenceForm = ({ pdfData, file, setPdfData, onSuccessfulUpload, clearFileInput, setFile }) => {
  const [isLoading, setIsLoading] = useState(false) // Loading state

  const saveData = () => {
    try {
      const user_id = getUserIdFromLocalStorage()
      if (user_id) {
        setIsLoading(true)
        handlePdfUpload(file, pdfData, 'files')
          .then((response) => {
            const fileExtension = file ? file.name.split('.').pop() : ''
            console.log('API Response:', response.data)
            onSuccessfulUpload({
              file_name: file ? file.name.replace(new RegExp(`\\.${fileExtension}$`), '') : '',
              author_name: pdfData.author_name,
              claim: pdfData.claim,
              annotation: pdfData.annotation,
              citation: pdfData.citation,
              publication_date: pdfData.publication_date
            })
            // Clearing input fields
            setPdfData({
              author_name: '',
              publication_date: '',
              claim: '',
              annotation: '',
              citation: ''
            })
            setFile('')
            toast.success('Data saved successfully', { autoClose: 3000 })
            clearFileInput()
          })
          .catch((error) => {
            console.error('Error sending data to the API:', error)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    } catch (error) {
      console.error('Error processing data:', error)
      setIsLoading(false)
    }
  }

  const oneFieldFilled = pdfData.author_name || pdfData.claim || pdfData.publication_date || pdfData.annotation || pdfData.citation
  return (
    <>
      <div className='input-boxes'>
        <input
          type='text'
          id='input1'
          placeholder='Author Name'
          value={pdfData.author_name}
          onChange={(e) => setPdfData({ ...pdfData, author_name: e.target.value })}
        />
        <input
          type='text'
          id='input2'
          placeholder='Claim'
          value={pdfData.claim}
          onChange={(e) => setPdfData({ ...pdfData, claim: e.target.value })}
        />
        <input
          type='text'
          id='input3'
          placeholder='Publication Date'
          value={pdfData.publication_date}
          onChange={(e) => setPdfData({ ...pdfData, publication_date: e.target.value })}
        />
        <input
          type='text'
          id='input4'
          placeholder='Annotation'
          value={pdfData.annotation}
          onChange={(e) => setPdfData({ ...pdfData, annotation: e.target.value })}
        />
        <input
          type='text'
          id='input5'
          placeholder='Citation'
          value={pdfData.citation}
          onChange={(e) => setPdfData({ ...pdfData, citation: e.target.value })}
        />
      </div>
      <div className='save-button'>
        <button onClick={saveData} disabled={isLoading || !oneFieldFilled}>
          {isLoading ? (
            <div className='loader'></div> // Loader while loading
          ) : (
            'Save Reference' // Button text when not loading
          )}
        </button>
      </div>
    </>
  )
}

ReferenceForm.propTypes = {
  pdfData: PropTypes.shape({
    author_name: PropTypes.string,
    publication_date: PropTypes.string,
    claim: PropTypes.string,
    annotation: PropTypes.string,
    citation: PropTypes.string
  }),
  file: PropTypes.instanceOf(File),
  setPdfData: PropTypes.func.isRequired,
  onSuccessfulUpload: PropTypes.func.isRequired,
  clearFileInput: PropTypes.func.isRequired,
  setFile: PropTypes.func
}

export default ReferenceForm
