// PDFPop.js
import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page } from 'react-pdf'
import './index.scss'

const PDFPop = ({ popupUrl, currentPage, numPages, isLoadingPDF, handlePrevPage, handleNextPage, closePopup }) => {
  return (
    <div>
      <div className='pdf-popup'>
        <div className='pdf-wrapper'>
          <button onClick={closePopup} className='close'>
            &times;
          </button>
          {isLoadingPDF ? (
            <div className='pdf-loader'></div>
          ) : (
            <Document file={popupUrl}>
              <Page pageNumber={currentPage} width={1000} />
            </Document>
          )}
          <div className='page-buttons'>
            <button className='previous-btn' onClick={handlePrevPage} disabled={currentPage === 1}>
              &#8249;
            </button>
            <button className='next-btn' onClick={handleNextPage} disabled={currentPage === numPages}>
              &#8250;
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

PDFPop.propTypes = {
  popupUrl: PropTypes.string,
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
  isLoadingPDF: PropTypes.bool,
  handlePrevPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  closePopup: PropTypes.func
}

export default PDFPop
