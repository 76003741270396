import React, { useEffect, useState } from 'react'
import './index.scss'
import axios from 'axios'
import toast from 'react-hot-toast'
import Layout from 'view/layout/Header'
// import { useNavigate } from 'react-router'

const TempNameEmail = () => {
  //Form Process
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: ''
  })
  // const navigate = useNavigate()
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    const role = localStorage.getItem('user_role')
    if (role !== '1') {
      // navigate('/unauthorized')
      setAccessDenied(true)
    }
  }, [])
  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validationErrors = validateForm(formData)

    if (Object.keys(validationErrors).length === 0) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL + '/api/temp-user-registration'
        const headers = {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY
        }

        await axios.post(apiUrl, formData, { headers: headers })

        setFormData({
          username: '',
          email: '',
          password: ''
        })
        setErrors({})
        toast.success('Temporary User Created Successfully!')
      } catch (error) {
        console.error('Error:', error)
        toast.error('Error creating temporary user')
      }
    } else {
      setErrors(validationErrors)
    }
  }

  const validateForm = (data) => {
    const errors = {}

    if (!data.username.trim()) {
      errors.username = 'Username is required'
    }

    if (!data.email.trim()) {
      errors.email = 'Email is required'
    } else if (!isValidEmail(data.email)) {
      errors.email = 'Invalid email address'
    }

    if (!data.password.trim()) {
      errors.password = 'Password is required'
    }

    return errors
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  return (
    <div className='container'>
      <Layout />
      <div className='container1'>
        {accessDenied ? (
          <div className='access-wrapper'>
            <div className='access-denied'>
              <h1>Access Denied</h1>
              <p>You do not have permission to access this page.</p>
            </div>
          </div>
        ) : (
          <div className='temp-wrapper'>
            <div className='container-right'>
              <form className='user-form' onSubmit={handleSubmit}>
                <p className='heading2'></p>

                <div className='input-box'>
                  <input type='text' name='username' placeholder='User Name' value={formData.username} onChange={handleInputChange} />
                  {errors.username && <span className='error-color'>{errors.username}</span>}
                </div>
                <div className='input-box'>
                  <input type='email' name='email' placeholder='Email' value={formData.email} onChange={handleInputChange} />
                  {errors.email && <span className='error-color'>{errors.email}</span>}
                </div>
                <div className='input-box'>
                  <input type='password' name='password' placeholder='Password' value={formData.password} onChange={handleInputChange} />
                  {errors.password && <span className='error-color'>{errors.password}</span>}
                </div>
                <button className='register-button'>Create User</button>
              </form>
            </div>
          </div>
        )}
        {/* Sign in form */}
        <div>
          <div className='container3'>
            <span className='terms'>
              <p>40 Knots™ 888 Prospect Street Suite 200 La Jolla, California 92037 USA</p>
              <p>
                <b>Terms of Use | Privacy Policy | Cookie Policy</b>
              </p>
              <p>© 2023 Corsaire Corporation™. All rights reserved.</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TempNameEmail
