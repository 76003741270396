import { getUserIdFromLocalStorage } from 'config/apiConfig'
import s3, { bucketName } from 'config/s3Config'
import toast from 'react-hot-toast'

export async function generatePresignedUrl(s3Key, expirationInSeconds = 3600) {
  const params = {
    Bucket: bucketName,
    Key: s3Key,
    Expires: expirationInSeconds
  }
  try {
    const url = await s3.getSignedUrlPromise('getObject', params)

    return url
  } catch (error) {
    console.error('Error generating presigned URL:', error)
    throw error
  }
}
export async function getObjectURL(key) {
  const url = `https://${bucketName}.s3.amazonaws.com/${key}`
  return url
}
export function getObjectURLSync(key) {
  const url = `https://${bucketName}.s3.amazonaws.com/${key}`
  return url
}

export async function uploadTos3(file, key) {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: bucketName,
      Key: key,
      Body: file
    }

    s3.upload(params, (err, data) => {
      if (err) {
        console.error('Error uploading to S3:', err)
        reject(err) // Reject the Promise if there's an error
      } else {
        console.log('Uploaded to s3', data)
        resolve(data) // Resolve the Promise with the uploaded data
      }
    })
  })
}

export async function deleteFroms3(key) {
  const params = {
    Bucket: bucketName,
    Key: key
  }
  s3.deleteObject(params, (err, data) => {
    if (err) {
      console.error('Error uploading to S3:', err)
    } else {
      return data
    }
  })
}

export async function deleteVersionFolderFromS3({ projectID, versionID }) {
  // Error handling and validation (improved)
  if (!projectID || !versionID) {
    throw new Error('Missing required parameters: projectID and versionID')
  }

  try {
    const user_id = getUserIdFromLocalStorage()
    const keyPrefix = `${user_id}/projects/project${projectID}/version${versionID}/`

    // Use listObjectsV2 for non-existent folders (important)
    const listParams = { Bucket: bucketName, Prefix: keyPrefix }
    const listedObjects = await s3.listObjectsV2(listParams).promise()

    if (!listedObjects.Contents.length) {
      console.log(`Folder '${keyPrefix}' does not exist`)
      return // No objects to delete, exit gracefully
    }

    // Efficiently delete objects with DeleteObjects (recommended)
    const deleteParams = {
      Bucket: bucketName,
      Delete: {
        Objects: listedObjects.Contents.map((obj) => ({ Key: obj.Key }))
      }
    }
    await s3.deleteObjects(deleteParams).promise()
    toast.success(`Successfully deleted version${versionID} template data from cloud`)
    console.log(`Successfully deleted version folder '${keyPrefix}'`)
  } catch (err) {
    console.error('Error deleting folder:', err)
    throw err // Re-throw for potential handling in calling code
  }
}

export async function deleteProjectFolderFromS3({ projectID }) {
  // Error handling and validation (improved)
  if (!projectID) {
    throw new Error('Missing required parameters: projectID')
  }

  try {
    const user_id = getUserIdFromLocalStorage()
    const keyPrefix = `${user_id}/projects/project${projectID}/`

    // Use listObjectsV2 for non-existent folders (important)
    const listParams = { Bucket: bucketName, Prefix: keyPrefix }
    const listedObjects = await s3.listObjectsV2(listParams).promise()

    if (!listedObjects.Contents.length) {
      console.log(`Folder '${keyPrefix}' does not exist`)
      return // No objects to delete, exit gracefully
    }

    // Efficiently delete objects with DeleteObjects (recommended)
    const deleteParams = {
      Bucket: bucketName,
      Delete: {
        Objects: listedObjects.Contents.map((obj) => ({ Key: obj.Key }))
      }
    }
    await s3.deleteObjects(deleteParams).promise()
    toast.success(`Successfully deleted project${projectID} template data from cloud`)
    console.log(`Successfully deleted preojct folder`)
  } catch (err) {
    console.error('Error deleting folder:', err)
    throw err // Re-throw for potential handling in calling code
  }
}

export async function copyFilesFromSourceToTarget({ projectID, sourceVersionID, targetVersionID }) {
  if (projectID && sourceVersionID && targetVersionID) {
    const user_id = getUserIdFromLocalStorage()
    const sourceFolder = `${user_id}/projects/project${projectID}/version${sourceVersionID}/`
    const targetFolder = `${user_id}/projects/project${projectID}/version${targetVersionID}/`
    const folderToMove = sourceFolder // old folder name
    const destinationFolder = targetFolder // new destination folder
    try {
      const listObjectsResponse = await s3
        .listObjectsV2({
          Bucket: bucketName,
          Prefix: folderToMove
        })
        .promise()

      const folderContentInfo = listObjectsResponse.Contents
      const folderPrefix = listObjectsResponse.Prefix

      return await Promise.all(
        folderContentInfo.map(async (fileInfo) => {
          const targetKey = `${destinationFolder}${fileInfo.Key.replace(folderPrefix, '')}`

          const copyResponse = await s3
            .copyObject({
              Bucket: bucketName,
              CopySource: `${bucketName}/${fileInfo.Key}`, // old file Key
              Key: targetKey // new file Key
            })
            .promise()

          console.log(`Copied ${fileInfo.Key}`, copyResponse)
        })
      )

      // toast.success(`Version${targetVersionID} template data created successfully on cloud`)
    } catch (err) {
      console.error(err) // error handling
    }
  }
}
