import React, { useState } from 'react'
import PropTypes from 'prop-types'
import '../../mycolorpalettes/index.scss'
import AddLogo from 'assets/images/logo.svg'
import { HexColorPicker } from 'react-colorful'
export const ColorPalette = ({ data, onClickColor, handleColorInput, handleColorPalatte }) => {
  const [openPalatte, setOpenPalatte] = useState()
  function handleOpenPalatte(e) {
    setOpenPalatte(e.target.id)
  }
  function handleClosePalatte() {
    setTimeout(() => {
      setOpenPalatte(null)
    }, 300)
  }
  function handleChangeColor(color, id, isPaletteControl) {
    handleColorPalatte(color, id, isPaletteControl)
  }
  return (
    <>
      <div className='color-container-lib' onMouseLeave={handleClosePalatte}>
        {data?.map(({ id, title, color_code }) => (
          <React.Fragment key={id}>
            <div className='color-container-box'>
              {openPalatte == id && <HexColorPicker onChange={(color) => handleChangeColor(color, id, 'true')} />}
              <div className='color-container-item'>
                <div className='color-category-name'>{title}</div>
                <div className='color-box' id={id} onClick={handleOpenPalatte} style={{ backgroundColor: color_code }} />
                <div className='color-code-lib'>
                  <input type='text' onChange={(e) => handleColorInput(e, id)} value={color_code} />
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
        {data.length < 5 && (
          <div className='add-color' onClick={onClickColor}>
            <div>
              <img src={AddLogo} alt='add color' />
            </div>
            <div className='add-file'>Add a color</div>
          </div>
        )}
      </div>
    </>
  )
}
ColorPalette.propTypes = {
  data: PropTypes.array,
  onClickColor: PropTypes.func,
  handleColorInput: PropTypes.func,
  handleColorPalatte: PropTypes.func
}
