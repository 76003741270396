import React, { useEffect, useState } from 'react'
import './index.scss'
import Layout from 'view/layout/Header'
import LeftSection from 'view/layout/Left-Section'
import MyFont from 'view/myfont/font'
import { fetchFonts } from './font/utilities/fonts'
import MySavedFont from './font/MySavedFont'

const MyFonts = () => {
  // const [count, setCount] = useState(0)

  const [savedFonts, setSavedFonts] = useState([])

  const fetchData = async () => {
    const { response, error } = await fetchFonts()
    if (error !== null) {
      console.error('Error fetching data:', error)
    } else {
      setSavedFonts(response.data)
    }
  }

  useEffect(() => {
    document.title = 'My Fonts'
    fetchData()
  }, [])

  return (
    <>
      <div className='container-div'>
        <Layout />
        <div className='container-div2'>
          <LeftSection name='fonts' />

          <div className='right-container myfont_container'>
            <p className='right-section-title'>My Fonts</p>
            {/* <MyFont savedFonts={savedFonts} onSave={fetchData} /> */}
            {/* <MySavedFont savedFonts={savedFonts} onSave={fetchData} /> */}
            <MyFont savedFonts={Array.isArray(savedFonts) ? savedFonts : []} onSave={fetchData} />
            <MySavedFont savedFonts={Array.isArray(savedFonts) ? savedFonts : []} onSave={fetchData} />
          </div>
        </div>
      </div>
    </>
  )
}

export default MyFonts
