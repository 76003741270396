import { headers, getUserIdFromLocalStorage } from 'config/apiConfig'
import axios from 'axios'

export const fetchColorPalette = async () => {
  let res = null
  let err = null
  try {
    const userId = getUserIdFromLocalStorage()
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/select/${userId}/color_palettes`
    const response = await axios.get(apiUrl, { headers })
    res = response
  } catch (error) {
    err = error
  }
  return {
    response: res,
    error: err
  }
}

export const insertColorPalette = async (datapalette) => {
  let res = null
  let err = null
  try {
    const apiUrl = process.env.REACT_APP_API_URL + '/api/insert/color_palettes'
    const response = await axios.post(apiUrl, datapalette, { headers })
    res = response
  } catch (error) {
    err = error
  }
  return {
    response: res,
    error: err
  }
}
