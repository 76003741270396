import React, { useState } from 'react'
import PdfPreview from 'components/pdf-to-image'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const MySavedIsi = ({ myIsiData }) => {
  const [expand, setExpand] = useState(null)

  const toggleDescription = (name) => {
    setExpand(expand !== name ? name : null)
  }

  return (
    <React.Fragment>
      <div className='my-saved-isi-doc'>
        {myIsiData.length > 0 && <p className='create-new-color'>My Saved ISI Documents</p>}
        {myIsiData.length > 0 ? (
          myIsiData.map((isidata, index) => {
            return (
              <div key={index} className='isi-saved-doc-info'>
                <div className='isi-saved-doc-img'>{isidata.file_url && <PdfPreview s3Link={isidata.file_url} />}</div>
                <div className='isi-saved-doc-data'>
                  <Link to={`/myimportant-saftey-information/details/${isidata.isi_id}`}>
                    <div className='isi-info'>{isidata.file_name}</div>
                  </Link>
                  <div className='isi-update'>Last updated: {isidata.last_updated}</div>

                  <div className='isi-info isi-border-bottom'>
                    <p className='isi-title' onClick={() => toggleDescription(`indication-${isidata.isi_id}`)}>
                      Indication
                    </p>
                    {expand === `indication-${isidata.isi_id}` && <p className='isi-desc'>{isidata.indication}</p>}
                  </div>
                  <div className='isi-info isi-border-bottom'>
                    <p className='isi-title' onClick={() => toggleDescription(`information-${isidata.isi_id}`)}>
                      IMPORTANT SAFETY INFORMATION
                    </p>
                    {expand === `information-${isidata.isi_id}` && <p className='isi-desc'>{isidata.important_safety_information}</p>}
                  </div>
                  <div className='isi-info'>
                    <p className='isi-title' onClick={() => toggleDescription(`please-${isidata.isi_id}`)}>
                      “Please see...”
                    </p>
                    {expand === `please-${isidata.isi_id}` && <p className='isi-desc'>{isidata.please_see}</p>}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div className='no-color-palettes'></div>
        )}
      </div>
    </React.Fragment>
  )
}

export default MySavedIsi
MySavedIsi.propTypes = {
  myIsiData: PropTypes.array
}
