import React, { useEffect, useState } from 'react'
import './index.scss'
import Layout from 'view/layout/Header'
import LeftSection from 'view/layout/Left-Section'
import { useParams } from 'react-router-dom'
import { getIsiDetailsApi } from 'helper/apiHelper'
import PdfPreview from 'components/pdf-to-image'

const MyISIDetails = () => {
  const { id } = useParams()
  const [details, setDetails] = useState([{}])
  const [expand, setExpand] = useState(null)

  useEffect(() => {
    fetchISIData(id)
  }, [id])

  const fetchISIData = async () => {
    const updatedData = await getIsiDetailsApi(id)
    setDetails(updatedData)
  }

  const toggleDesc = (name) => {
    setExpand(expand !== name ? name : null)
  }
  return (
    <div className='container-div'>
      <Layout />
      <div className='container-div2'>
        <LeftSection name='isi' />
        <div className='right-container my-isi'>
          <div className='right-section-title'>My Important Safety Information (ISI)</div>
          <div className='my-saved-isi-doc'>
            <p className='create-new-color'>My Saved ISI Documents</p>
            {details.length > 0 &&
              details?.map((detail, index) => (
                <React.Fragment key={index}>
                  <div className='isi-saved-doc-info'>
                    <div className='isi-saved-doc-img'>{detail.file_url && <PdfPreview s3Link={detail.file_url} />}</div>
                    <div className='isi-saved-doc-data'>
                      <div className='isi-info'>{detail.file_name}</div>
                      <div className='isi-update'>Last updated:{detail.last_updated}</div>
                      <div className='isi-para isi-border-bottom'>
                        <p className='isi-title' onClick={() => toggleDesc(`indication-${detail.isi_id}`)}>
                          Indication
                        </p>
                        {expand === `indication-${detail.isi_id}` && <span className='isi-short-para'>{detail.indication}</span>}
                      </div>
                      <div className='isi-para isi-border-bottom'>
                        <p className='isi-title' onClick={() => toggleDesc(`information-${detail.isi_id}`)}>
                          IMPORTANT SAFETY INFORMATION
                        </p>
                        {expand === `information-${detail.isi_id}` && (
                          <span className='isi-short-para'>{detail.important_safety_information}</span>
                        )}
                      </div>
                      <div className='isi-para isi-border-bottom'>
                        <p className='isi-title' onClick={() => toggleDesc(`warning-${detail.isi_id}`)}>
                          Warnings and Precautions
                        </p>
                        {expand === `warning-${detail.isi_id}` && <span className='isi-short-para'>{detail.warnings_precautions}</span>}
                      </div>
                      <div className='isi-para isi-border-bottom'>
                        <p className='isi-title' onClick={() => toggleDesc(`adverse-${detail.isi_id}`)}>
                          Adverse Reactions
                        </p>
                        {expand === `adverse-${detail.isi_id}` && <span className='isi-short-para'>{detail.adverse_reactions}</span>}
                      </div>
                      <div className='isi-para'>
                        <p className='isi-title' onClick={() => toggleDesc(`please-${detail.isi_id}`)}>
                          “Please see...”
                        </p>
                        {expand === `please-${detail.isi_id}` && <span className='isi-short-para'>{detail.please_see}</span>}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyISIDetails
