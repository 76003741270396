import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { pdfjs } from 'react-pdf'
import './index.scss'
import PDFPopup from 'components/pdf-popup'

const Author = ({ listData }) => {
  const [popupUrl, setPopupUrl] = useState(null)
  const [numPages, setNumPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingPDF, setIsLoadingPDF] = useState(false)

  const openPopup = (url, annotation) => {
    // Extract the page number from the annotation
    const pageNumber = annotation ? parseInt(annotation?.match(/\d+/), 10) : 1

    // If the extracted page number is valid, set currentPage to that page
    if (!isNaN(pageNumber) && pageNumber >= 1) {
      setCurrentPage(pageNumber)
    } else {
      // If no valid page number found in the annotation, default to page 1
      setCurrentPage(1)
    }

    // Set the popupUrl to open the PDF popup
    setPopupUrl(url)
  }

  const closePopup = () => {
    setPopupUrl(null)
  }

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  useEffect(() => {
    if (popupUrl) {
      setIsLoadingPDF(true)
      pdfjs.getDocument(popupUrl).promise.then((pdfDocument) => {
        setNumPages(pdfDocument.numPages)
        setIsLoadingPDF(false)
      })
    }
  }, [popupUrl])

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  // Group publications by author function
  function groupPublicationsByAuthor(publications) {
    const groupedData = {}
    publications.forEach((publication) => {
      const { author_name } = publication
      if (!groupedData[author_name]) {
        groupedData[author_name] = []
      }
      groupedData[author_name].push(publication)
    })

    return groupedData
  }

  // Function to get only the year from a date string
  function getPublicationYear(dateString) {
    const publicationDate = new Date(dateString)
    return publicationDate.getFullYear()
  }

  // Group the data by author's name
  const groupedData = groupPublicationsByAuthor(listData)

  return (
    <div className='author-container'>
      {Object.keys(groupedData).map((authorName) => (
        <div key={authorName} className='author-group'>
          <div className='container-heading'>
            {authorName}
            {groupedData[authorName][0].publication_date && (
              <span className='author-date'>&nbsp;{getPublicationYear(groupedData[authorName][0].publication_date)}</span>
            )}
          </div>
          <div className='document-grid'>
            {groupedData[authorName].map((publication, i) => (
              <div key={i} className='saved-pdf-container'>
                <div className='saved-pdf-info'>
                  <div className='container-left-side'>
                    {publication.claim && (
                      <>
                        <h3>CLAIM</h3>
                        <p>{publication.claim}</p>
                      </>
                    )}
                    {publication.annotation && (
                      <>
                        <h3>ANNOTATION</h3>
                        <p>{publication.annotation}</p>
                      </>
                    )}
                    {publication.citation && (
                      <>
                        <h3>CITATION</h3>
                        <p>{publication.citation}</p>
                      </>
                    )}
                    {publication.file_url && (
                      <>
                        <h3>PUBLICATION</h3>
                        <a
                          href='#see-publication'
                          className='anchor-tag'
                          onClick={() => openPopup(publication.file_url, publication.annotation)}
                        >
                          See Publication
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
      {popupUrl && (
        <PDFPopup
          popupUrl={popupUrl}
          currentPage={currentPage}
          numPages={numPages}
          isLoadingPDF={isLoadingPDF}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          closePopup={closePopup}
        />
      )}
    </div>
  )
}

Author.propTypes = {
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      claim: PropTypes.string,
      annotation: PropTypes.string,
      file_url: PropTypes.string,
      author_name: PropTypes.string,
      publication_date: PropTypes.string
    })
  )
}

export default Author
