import React, { useEffect, useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import Layout from 'view/layout/Header'

const RegisterPassword = () => {
  let navigate = useNavigate()
  //Form Process
  const [formData, setFormData] = useState({
    providedPassword: '',
    password: '',
    providedUsername: '',
    username: ''
  })
  const checkLocalUser = () => {
    const check = JSON.parse(localStorage.getItem('username'))
    if (check) {
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    checkLocalUser()
    document.title = 'Password Reset'
    const params = new URLSearchParams(window.location.search)
    const providedUsername = params.get('providedUsername')
    const username = params.get('username')

    setFormData((prevFormData) => ({
      ...prevFormData,
      providedUsername: providedUsername,
      username: username,
      userrole: 3
    }))
  }, [])

  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validationErrors = validateForm(formData)

    if (Object.keys(validationErrors).length === 0) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL + '/api/register-password'
        const headers = {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY
        }

        await axios.post(apiUrl, formData, { headers: headers })

        setFormData({
          providedusername: '',
          newusername: ''
        })
        setErrors({})
        toast.success('User Created Successfully!')
        navigate('/login?register=success')
      } catch (error) {
        console.error('Error:', error)
        toast.error('Error creating new user')
      }
    } else {
      setErrors(validationErrors)
    }
  }

  const validateForm = (data) => {
    const errors = {}

    if (!data.providedPassword.trim()) {
      errors.providedPassword = 'provided Password is required'
    }

    if (!data.password.trim()) {
      errors.password = 'Password is required'
    }

    return errors
  }

  return (
    <div className='container'>
      <Layout />
      <div className='container1'>
        <div className='container-left'>
          <h1 className='welcome'>Welcome</h1>
          <div className='first-line'>Thank you for registering with the 40 Knots AutonoME platform.</div>
          <p className='para'>
            If you’re on this page, you’ve spoken with one of our representatives and have been provided a temporary username and password.
          </p>
          <p className='para'>Please create a new, unique username and password.</p>
        </div>
        <div className='container-right'>
          <form className='user-form' onSubmit={handleSubmit}>
            <p className='heading2'>Create a unique password</p>

            <div className='input-box'>
              <input
                type='password'
                name='providedPassword'
                placeholder='Provided password'
                value={formData.providedPassword}
                onChange={handleInputChange}
              />
              {errors.providedPassword && <span className='error-color'>{errors.providedPassword}</span>}
            </div>
            <div className='input-box'>
              <input type='password' name='password' placeholder='New password' value={formData.password} onChange={handleInputChange} />
              {errors.password && <span className='error-color'>{errors.password}</span>}
            </div>
            <button className='register-button'>Register new Password</button>
          </form>
        </div>
        {/* Sign in form */}
        <div>
          <div className='container3'>
            <span className='terms'>
              <p>40 Knots™ 888 Prospect Street Suite 200 La Jolla, California 92037 USA</p>
              <p>
                <b>Terms of Use | Privacy Policy | Cookie Policy</b>
              </p>
              <p>© 2023 Corsaire Corporation™. All rights reserved.</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterPassword
