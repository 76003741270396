import React, { useEffect, useState, useRef } from 'react'
import toast from 'react-hot-toast'
import uploadIcon from 'assets/images/my-isi/isi-upload-icon.png'
import Layout from 'view/layout/Header'
import LeftSection from 'view/layout/Left-Section'
import PdfPreview from 'components/pdf-to-image'
import { getMyPiList, handlePiFileUpload } from 'helper/apiHelper'
import './index.scss'
import { checkFileLimit, checkFileSize } from 'config/apiConfig'

const MyPrescribingInformation = () => {
  var [uploadedFile, setUploadedFile] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const [myPiData, setMyPiData] = useState()
  const [isLoading, setIsLoading] = useState(false) // Loading state

  useEffect(() => {
    document.title = 'My Prescribing Information'
    fetchMyPiData()
  }, [])

  const fileInputRef = useRef(null)

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the file input
    }
  }

  const fetchMyPiData = async () => {
    const updatedData = await getMyPiList()
    setMyPiData(updatedData)
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleFileDrop = async (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (checkFileSize(file.size)) {
      if (checkFileLimit(myPiData.length)) {
        setUploadedFile(file)
      } else {
        toast.error('You cannot upload more than 50 files into your library')
      }
    } else {
      toast.error('Please upload a file with a size of 50MB or less.')
    }
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    if (checkFileSize(file.size)) {
      if (checkFileLimit(myPiData.length)) {
        setUploadedFile(file)
      } else {
        toast.error('You cannot upload more than 50 files into you library')
      }
    } else {
      toast.error('Please upload a file with a size of 50MB or less.')
    }
  }

  const uploadData = async (e, pi_id, filename) => {
    if (!uploadedFile && !pi_id) {
      toast.error('Please upload a PDF file before saving')
      return
    }
    if (!inputValue && !pi_id) {
      toast.error('PI name is not allowed to be empty')
      return
    }
    if (pi_id) {
      uploadedFile = e.target.files[0]
      setUploadedFile(uploadedFile)
    } else {
      filename = inputValue
    }

    setIsLoading(true) // Set loading state to true

    try {
      await handlePiFileUpload(uploadedFile, 'files', pi_id, filename)
      fetchMyPiData()
      toast.success('Saved Successfully')
      setInputValue('')
      setUploadedFile(null)
      clearFileInput()
    } catch (error) {
      toast.error('An error occurred') // You can add error handling here
    } finally {
      setIsLoading(false) // Set loading state back to false
    }
  }
  return (
    <div className='container-div'>
      <Layout />
      <div className='container-div2'>
        <LeftSection name='psi' />
        <div className='right-container my-isi'>
          <div className='right-section-title'>My Prescribing Information (PI)</div>

          <div className='isi-docs'>
            <div className='isi-doc-upload'>
              <form id='form-file-upload'>
                <label id='label-file-upload' htmlFor='input-file-upload'>
                  <div
                    className='isi-upload-dashed-border'
                    onDragOver={(e) => {
                      e.preventDefault()
                    }}
                    onDrop={handleFileDrop}
                  >
                    <p>Drag a File Here or</p>
                    <img src={uploadIcon} alt='' />
                    <input type='file' accept='.pdf' id='input-file-upload' onChange={handleFileChange} ref={fileInputRef} />

                    {uploadedFile && <div className='uploaded-file-name'>{uploadedFile.name}</div>}
                    <div className='upload-button'>Upload a File</div>
                  </div>
                </label>
              </form>
            </div>
            <div className='isi-add-new-doc'>
              <p className='create-new-color'>Add a New Document</p>
              <p className='isi-desc'>
                Start by adding a Word document or PDF into our system. Once your file is uploaded, simply provide a name and click “save”.
              </p>
              <div className='isi-inputs'>
                <input type='text' value={inputValue} onChange={handleInputChange} placeholder='Name Your PI' />
                <button type='button' onClick={uploadData} disabled={isLoading || !uploadedFile}>
                  {isLoading ? (
                    <div className='loader'></div> // Loader while loading
                  ) : (
                    'Save PI' // Button text when not loading
                  )}
                </button>
              </div>
            </div>
          </div>

          {myPiData?.length > 0 && (
            <div className='my-saved-isi-doc'>
              <p className='create-new-color'>My Saved PI Documents</p>
              {myPiData.map((container) => (
                <div key={container.id} className='isi-saved-doc-info'>
                  <div className='isi-saved-doc-img'>
                    <PdfPreview s3Link={container.file_url} />
                  </div>
                  <div className='isi-saved-doc-data'>
                    <div className='isi-info'>{container.file_name}</div>
                    <div className='isi-update'>Last Updated: {container.uploaded_date}</div>
                    <label className='upload-button-pi'>
                      <input
                        type='file'
                        accept='.pdf'
                        onChange={(e) => uploadData(e, container.pi_id, container.file_name)}
                        style={{ display: 'none' }}
                        id='file-1'
                      />
                      <div>
                        <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
                          <path
                            d='M24.1875 12.55C23.3375 8.2375 19.55 5 15 5C11.3875 5 8.25 7.05 6.6875 10.05C2.925 10.45 0 13.6375 0 17.5C0 21.6375 3.3625 25 7.5 25H23.75C27.2 25 30 22.2 30 18.75C30 15.45 27.4375 12.775 24.1875 12.55ZM23.75 22.5H7.5C4.7375 22.5 2.5 20.2625 2.5 17.5C2.5 14.9375 4.4125 12.8 6.95 12.5375L8.2875 12.4L8.9125 11.2125C10.1 8.925 12.425 7.5 15 7.5C18.275 7.5 21.1 9.825 21.7375 13.0375L22.1125 14.9125L24.025 15.05C25.975 15.175 27.5 16.8125 27.5 18.75C27.5 20.8125 25.8125 22.5 23.75 22.5ZM10 16.25H13.1875V20H16.8125V16.25H20L15 11.25L10 16.25Z'
                            fill='black'
                          />
                        </svg>
                        <span>Replace PI</span>
                      </div>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MyPrescribingInformation
