import React, { useEffect, useState } from 'react'
import '../../myfont/index.scss'
import AdjustFontComponent from './AdjustFontComponent'
import PreviewFontComponent from './PreviewFontComponent'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { insertFonts } from './utilities/fonts'
import { getUserIdFromLocalStorage, updateFonts, headers } from 'config/apiConfig'
import axios from 'axios'
import { getGoogleFontsAPI } from 'helper/apiHelper'
import CustomFontDropdown from './customdropdown'

const MyFont = ({ savedFonts, onSave, initData, handleDeleteConfirmation }) => {
  const [googleFonts, setGoogleFonts] = useState([])
  const [activeheadlineFontFamily, setActiveheadlineFontFamily] = useState(initData?.font_headline?.activeheadlineFontFamily || 'Open Sans')
  const [activesubheadlineFontFamily, setActivesubheadlineFontFamily] = useState(
    initData?.font_sub_headline?.activesubheadlineFontFamily || 'Open Sans'
  )
  const [activebodylineFontFamily, setActivebodylineFontFamily] = useState(initData?.font_body?.activebodylineFontFamily || 'Open Sans')

  const [headfontSize, setHeadFontSize] = useState(initData?.font_headline?.headfontSize || 24)
  const [subheadfontSize, setSubHeadFontSize] = useState(initData?.font_sub_headline?.subheadfontSize || 20)
  const [bodyfontSize, setBodyFontSize] = useState(initData?.font_body?.bodyfontSize || 16)

  const [isheadUnderlined, setHeadIsUnderlined] = useState(initData?.font_headline?.isheadUnderlined || false)
  const [isheadStrikedOut, setHeadIsStrikedOut] = useState(initData?.font_headline?.isheadStrikedOut || false)

  const [issubheadUnderlined, setSubHeadIsUnderlined] = useState(initData?.font_sub_headline?.issubheadUnderlined || false)
  const [issubheadStrikedOut, setSubHeadIsStrikedOut] = useState(initData?.font_sub_headline?.issubheadStrikedOut || false)

  const [isbodyUnderlined, setBodyIsUnderlined] = useState(initData?.font_body?.isbodyUnderlined || false)
  const [isbodyStrikedOut, setBodyIsStrikedOut] = useState(initData?.font_body?.isbodyStrikedOut || false)

  const [fontname, setFontName] = useState(initData?.font_name || '')
  const [resetForm, setResetForm] = useState(false)
  const userId = getUserIdFromLocalStorage()
  const maxFonts = 4

  useEffect(() => {
    const fetchFonts = async () => {
      const fontsData = await getGoogleFontsAPI()
      if (fontsData?.items) {
        const formattedFonts = fontsData.items.map((font) => ({
          value: font.family,
          label: font.family
        }))
        setGoogleFonts(formattedFonts)
      }
    }
    fetchFonts()
  }, [])

  useEffect(() => {
    if (initData) {
      // Use initData or API to fetch the fonts for the initial load
      setActiveheadlineFontFamily(initData?.font_headline?.activeheadlineFontFamily || 'Open Sans')
      setActivesubheadlineFontFamily(initData?.font_sub_headline?.activesubheadlineFontFamily || 'Open Sans')
      setActivebodylineFontFamily(initData?.font_body?.activebodylineFontFamily || 'Open Sans')
      setHeadFontSize(initData?.font_headline?.headfontSize || 24)
      setSubHeadFontSize(initData?.font_sub_headline?.subheadfontSize || 20)
      setBodyFontSize(initData?.font_body?.bodyfontSize || 16)
      setHeadIsUnderlined(initData?.font_headline?.isheadUnderlined || false)
      setHeadIsStrikedOut(initData?.font_headline?.isheadStrikedOut || false)
      setSubHeadIsUnderlined(initData?.font_sub_headline?.issubheadUnderlined || false)
      setSubHeadIsStrikedOut(initData?.font_sub_headline?.issubheadStrikedOut || false)
      setBodyIsUnderlined(initData?.font_body?.isbodyUnderlined || false)
      setBodyIsStrikedOut(initData?.font_body?.isbodyStrikedOut || false)
    }
  }, [initData]) // Only run on initData change or component mount

  const handleResetForm = () => {
    setResetForm(true)
    setTimeout(() => {
      setResetForm(false)
    }, 0)
  }

  useEffect(() => {
    if (resetForm) {
      setActiveheadlineFontFamily('Open Sans')
      setActivesubheadlineFontFamily('Open Sans')
      setActivebodylineFontFamily('Open Sans')
      setHeadFontSize(24)
      setHeadIsUnderlined(false)
      setHeadIsStrikedOut(false)
      setSubHeadFontSize(20)
      setSubHeadIsUnderlined(false)
      setSubHeadIsStrikedOut(false)
      setBodyFontSize(16)
      setBodyIsUnderlined(false)
      setBodyIsStrikedOut(false)
    }
  }, [resetForm])

  const handleFontChange = (selectedOption, setFontFamily) => {
    const fontFamily = selectedOption.value
    setFontFamily(fontFamily)

    const link = document.createElement('link')
    link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(' ', '+')}&display=swap`

    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }

  const getFontData = (userId, fontname) => ({
    user_id: userId,
    font_name: fontname,
    font_headline: JSON.stringify([
      {
        activeheadlineFontFamily,
        headfontSize,
        isheadUnderlined,
        isheadStrikedOut
      }
    ]),
    font_sub_headline: JSON.stringify([
      {
        activesubheadlineFontFamily,
        subheadfontSize,
        issubheadUnderlined,
        issubheadStrikedOut
      }
    ]),
    font_body: JSON.stringify([
      {
        activebodylineFontFamily,
        bodyfontSize,
        isbodyUnderlined,
        isbodyStrikedOut
      }
    ])
  })

  const saveFonts = async () => {
    if (!fontname.trim()) {
      toast.error('Font name is required.')
      return
    }
    if (savedFonts.length === maxFonts && !initData) {
      toast.error('You have reached the maximum limit of 4 fonts.')
      return
    }

    const datafonts = getFontData(userId, fontname)

    try {
      let response
      if (initData) {
        response = await axios.post(updateFonts(initData.font_id), datafonts, { headers })
      } else {
        response = await insertFonts(datafonts, { headers })
        setFontName('')
        handleResetForm()
      }

      if (response.status === 200) {
        const successMessage = initData ? 'Font updated successfully.' : 'Font saved successfully.'
        console.log(successMessage)
        toast.success(successMessage)
        onSave()
      } else {
        const errorMessage = initData ? 'Failed to update Fonts data.' : 'Failed to save Fonts data.'
        console.error(errorMessage)
        toast.error(errorMessage)
      }
    } catch (error) {
      console.error('An error occurred while saving Fonts data:', error)
      toast.error('An error occurred while saving Fonts data')
    }
  }

  return (
    <>
      <div className='myfont-header'>
        {!initData && <p className='create-new-color'>Create a New Font</p>}
        <input type='text' placeholder='Font Name' value={fontname} onChange={(e) => setFontName(e.target.value)} />
      </div>
      <div className='myfont'>
        <div className='right-container-fonts'>
          <p className='heading'>Headline Font</p>
          {/* <Select
            options={googleFonts}
            value={{ value: activeheadlineFontFamily, label: activeheadlineFontFamily }}
            onChange={(option) => handleFontChange(option, setActiveheadlineFontFamily)}
            styles={customStyles}
          /> */}
          <CustomFontDropdown
            options={googleFonts}
            value={activeheadlineFontFamily}
            onChange={(option) => handleFontChange(option, setActiveheadlineFontFamily)}
          />

          <AdjustFontComponent
            decreaseFontSize={() => setHeadFontSize(Math.max(6, headfontSize - 2))}
            fontSize={headfontSize}
            increaseFontSize={() => setHeadFontSize(headfontSize + 2)}
            isUnderlined={isheadUnderlined}
            isStrikedOut={isheadStrikedOut}
            toggleUnderline={() => setHeadIsUnderlined(!isheadUnderlined)}
            toggleStrikeout={() => setHeadIsStrikedOut(!isheadStrikedOut)}
          />
          <CustomFontDropdown
            options={googleFonts}
            value={activesubheadlineFontFamily}
            onChange={(option) => handleFontChange(option, setActivesubheadlineFontFamily)}
          />
          <AdjustFontComponent
            decreaseFontSize={() => setSubHeadFontSize(Math.max(6, subheadfontSize - 2))}
            fontSize={subheadfontSize}
            increaseFontSize={() => setSubHeadFontSize(subheadfontSize + 2)}
            isUnderlined={issubheadUnderlined}
            isStrikedOut={issubheadStrikedOut}
            toggleUnderline={() => setSubHeadIsUnderlined(!issubheadUnderlined)}
            toggleStrikeout={() => setSubHeadIsStrikedOut(!issubheadStrikedOut)}
          />
          <CustomFontDropdown
            options={googleFonts}
            value={activebodylineFontFamily}
            onChange={(option) => handleFontChange(option, setActivebodylineFontFamily)}
          />
          <AdjustFontComponent
            decreaseFontSize={() => setBodyFontSize(Math.max(6, bodyfontSize - 2))}
            fontSize={bodyfontSize}
            increaseFontSize={() => setBodyFontSize(bodyfontSize + 2)}
            isUnderlined={isbodyUnderlined}
            isStrikedOut={isbodyStrikedOut}
            toggleUnderline={() => setBodyIsUnderlined(!isbodyUnderlined)}
            toggleStrikeout={() => setBodyIsStrikedOut(!isbodyStrikedOut)}
          />
        </div>
        <PreviewFontComponent
          headStyle={{
            fontFamily: activeheadlineFontFamily,
            fontSize: `${headfontSize}px`,
            textDecoration: `${isheadUnderlined ? 'underline' : ''} ${isheadStrikedOut ? 'line-through' : ''}`
          }}
          subheadStyle={{
            fontFamily: activesubheadlineFontFamily,
            fontSize: `${subheadfontSize}px`,
            textDecoration: `${issubheadUnderlined ? 'underline' : ''} ${issubheadStrikedOut ? 'line-through' : ''}`
          }}
          bodyStyle={{
            fontFamily: activebodylineFontFamily,
            fontSize: `${bodyfontSize}px`,
            textDecoration: `${isbodyUnderlined ? 'underline' : ''} ${isbodyStrikedOut ? 'line-through' : ''}`
          }}
          fontId={initData?.font_id}
        />
      </div>
      <div className='update-delete-button'>
        <div className='save_div_row font'>
          <button type='button' onClick={saveFonts}>
            {initData ? 'Update' : 'Save'}
          </button>

          {initData && (
            <button className='update-button' onClick={handleDeleteConfirmation}>
              Delete
            </button>
          )}
        </div>
      </div>
    </>
  )
}

MyFont.propTypes = {
  savedFonts: PropTypes.array,
  onSave: PropTypes.func,
  initData: PropTypes.object,
  handleDeleteConfirmation: PropTypes.func
}

export default MyFont
