import React, { useState, useEffect, useRef } from 'react'
import './custom.scss'

const CustomFontDropdown = ({ options, value, onChange }) => {
  const [loadedFonts, setLoadedFonts] = useState(new Set())
  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef(null)
  const dropdownTriggerRef = useRef(null)
  const observerRef = useRef(null)

  const loadFont = async (fontFamily) => {
    if (loadedFonts.has(fontFamily)) return

    try {
      const link = document.createElement('link')
      link.href = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/\s+/g, '+')}`
      link.rel = 'stylesheet'

      await new Promise((resolve, reject) => {
        link.onload = resolve
        link.onerror = reject
        document.head.appendChild(link)
      })

      setLoadedFonts((prev) => new Set([...prev, fontFamily]))
    } catch (error) {
      console.error(`Error loading font ${fontFamily}:`, error)
    }
  }

  useEffect(() => {
    if (value) {
      loadFont(value)
    }
  }, [value])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownTriggerRef.current &&
        !dropdownTriggerRef.current.contains(event.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleSelect = (option) => {
    onChange(option)
    setIsOpen(false)
  }

  const handleOpen = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    if (!isOpen) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const fontFamily = entry.target.dataset.fontFamily
            if (fontFamily) {
              loadFont(fontFamily)
              observer.unobserve(entry.target)
            }
          }
        })
      },
      { threshold: 0.5 }
    )

    const optionsElements = dropdownRef.current.querySelectorAll('.custom-dropdown__option')
    optionsElements.forEach((option) => {
      observer.observe(option)
    })

    observerRef.current = observer

    return () => {
      optionsElements.forEach((option) => observer.unobserve(option))
    }
  }, [isOpen])

  return (
    <div className='custom-dropdown' ref={dropdownRef}>
      <button type='button' className='custom-dropdown__trigger' onClick={handleOpen} ref={dropdownTriggerRef}>
        <span className='custom-dropdown__selected' style={{ fontFamily: value }}>
          {value}
        </span>
        <span className={`custom-dropdown__arrow ${isOpen ? 'open' : ''}`}>▼</span>
      </button>

      {isOpen && (
        <div className='custom-dropdown__content'>
          {options.map((option, index) => (
            <div
              key={index}
              className={`custom-dropdown__option ${option.value === value ? 'selected' : ''}`}
              onClick={() => handleSelect(option)}
              style={{
                fontFamily: option.value,
                opacity: loadedFonts.has(option.value) ? 1 : 0.5
              }}
              data-font-family={option.value}
            >
              {option.value}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomFontDropdown
