import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MyFont from 'view/myfont/font'
import '../index.scss'
import { deletemyFont } from 'helper/apiHelper'
import toast from 'react-hot-toast'

const MySavedFont = ({ savedFonts, onSave }) => {
  return (
    <div className='saved-fonts'>
      {savedFonts.length > 0 && <p className='create-new-color'>My Saved Fonts</p>}

      {Array.isArray(savedFonts) ? (
        savedFonts.map((savefont) => <PrintFonts key={savefont.font_id} savefont={savefont} onSave={onSave} savedFonts={savedFonts} />)
      ) : (
        <div className='no-color-palettes'></div>
      )}
    </div>
  )
}
export default MySavedFont
MySavedFont.propTypes = {
  savedFonts: PropTypes.array,
  onSave: PropTypes.func
}

const PrintFonts = ({ savefont, onSave, savedFonts }) => {
  const headfontvalue = JSON.parse(savefont.font_headline)[0]
  const subheadfontvalue = JSON.parse(savefont.font_sub_headline)[0]
  const bodyfontvalue = JSON.parse(savefont.font_body)[0]
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(true)
  }

  const handleDelete = () => {
    deletemyFont(savefont.font_id)
      .then(() => {
        setShowDeleteConfirmation(false)
        onSave()
        toast.success('Font deleted successfully.')
      })
      .catch(() => {
        // Handle delete error
        setShowDeleteConfirmation(false)
        toast.error('Error deleting font')
      })
  }

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false)
  }

  return (
    <div className='font-item'>
      <div>
        <MyFont
          savedFonts={savedFonts}
          onSave={() => onSave()}
          initData={{
            font_id: savefont.font_id,
            font_name: savefont.font_name,
            font_headline: headfontvalue,
            font_sub_headline: subheadfontvalue,
            font_body: bodyfontvalue
          }}
          handleDeleteConfirmation={handleDeleteConfirmation}
        />
      </div>

      {showDeleteConfirmation && (
        <div className='modal'>
          <div className='modal-content '>
            <p>Are you sure you want to delete?</p>
            <button onClick={handleDelete} className='delete-button'>
              Yes
            </button>
            <button onClick={handleCancelDelete} className='cancel-button'>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

PrintFonts.propTypes = {
  savedFonts: PropTypes.array,
  savefont: PropTypes.object,
  onSave: PropTypes.func
}
