import React, { useEffect } from 'react'
import Layout from 'view/layout/Header'
import './index.scss'
import Project from './project'
import { useProjectList } from 'components/customhook'

const MyProjects = () => {
  const { projectList, fetchProjectList, deleteProject } = useProjectList()

  useEffect(() => {
    document.title = 'My Projects'
    fetchProjectList()
  }, [])

  return (
    <div className='container-div'>
      <div className='my-projects-container'>
        <Layout projectList={projectList} />
        <div className='container-myprojects'>
          <div className='myprojects-section'>
            {projectList.length === 0 ? (
              <p className='message'>No saved projects found.</p>
            ) : (
              projectList.map((project, index) => <Project deleteProject={deleteProject} key={index} project={project} />)
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyProjects
