import { handleQrUpload } from 'helper/apiHelper'

import { QRCodeSVG } from 'qrcode.react'
import ReactDOMServer from 'react-dom/server'
import toast from 'react-hot-toast'

export const getContrastRatio = (foreground, background) => {
  const lumA = getRelativeLuminance(foreground)
  const lumB = getRelativeLuminance(background)
  return (Math.max(lumA, lumB) + 0.05) / (Math.min(lumA, lumB) + 0.05)
}

export const getRelativeLuminance = (color) => {
  const rgb = parseInt(color.slice(1), 16)
  const r = (rgb >> 16) / 255
  const g = ((rgb >> 8) & 0xff) / 255
  const b = (rgb & 0xff) / 255

  const srgb = [r, g, b].map((channel) => (channel <= 0.03928 ? channel / 12.92 : ((channel + 0.055) / 1.055) ** 2.4))

  return 0.2126 * srgb[0] + 0.7152 * srgb[1] + 0.0722 * srgb[2]
}

export const handleSavePNG = async (text, foreground, background) => {
  if (text) {
    const qrCodeElement = QRCodeSVG({ value: text, size: 128, fgColor: foreground, bgColor: background, includeMargin: true })

    const svgString = ReactDOMServer.renderToString(
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='128'
        height='128'
        viewBox='0 0 128 128'
        fill={foreground}
        style={{ backgroundColor: background }}
      >
        <rect width='100%' height='100%' fill={background} />
        {qrCodeElement}
      </svg>
    )

    // Convert SVG to PNG Blob
    const canvas = document.createElement('canvas')
    canvas.width = 128
    canvas.height = 128
    const ctx = canvas.getContext('2d')

    // Create a new Image object and set its source to the SVG string
    const img = new Image()
    img.src = 'data:image/svg+xml;base64,' + btoa(decodeURIComponent(encodeURIComponent(svgString)))

    // Wait for the image to load
    await new Promise((resolve) => {
      img.onload = resolve
    })

    // Draw the SVG image onto the canvas
    ctx.drawImage(img, 0, 0)

    // Convert the canvas to PNG Blob
    const pngBlob = await new Promise((resolve) => {
      canvas.toBlob((blob) => resolve(blob), 'image/png')
    })
    // Create and download the PNG

    const response = await handleQrUpload(text, pngBlob, 'png')

    if (response && response.status === 200) {
      toast.success('QR code uploaded successfully')
    } else {
      toast.error('QR code upload failed')
    }
  }
}
export const handleSaveSVG = async (text, foreground, background) => {
  if (text) {
    const qrCodeElement = QRCodeSVG({ value: text, size: 128, fgColor: foreground, bgColor: background, includeMargin: true })

    const svgString = ReactDOMServer.renderToString(
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='128'
        height='128'
        viewBox='0 0 128 128'
        fill={foreground}
        style={{ backgroundColor: background }}
      >
        <rect width='100%' height='100%' fill={background} />
        {qrCodeElement}
      </svg>
    )

    // Create and download the SVG
    const file = new Blob([svgString], { type: 'image/svg+xml' })
    const response = await handleQrUpload(text, file, 'svg')
    if (response && response.status === 200) {
      toast.success('QR code uploaded successfully')
    } else {
      toast.error('QR code upload failed')
    }
  }
}

export const isValidHexColor = (color) => {
  const hexColorRegex = /^#([0-9A-Fa-f]{3}){1,2}$/
  return hexColorRegex.test(color)
}

export const handleSave = async (format, imageUrl) => {
  try {
    let blob

    if (format === 'svg') {
      blob = new Blob([imageUrl], { type: 'image/svg+xml' })
    } else if (format === 'png') {
      const link = document.createElement('a')
      link.href = imageUrl
      link.download = `image.${format}`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      return
    } else {
      console.error('Invalid format specified.')
      return
    }

    const link = document.createElement('a')
    const url = window.URL.createObjectURL(blob)

    link.href = url
    link.download = `image.${format}`

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading image:', error)
  }
}

export const fetchSVGString = async (url) => {
  try {
    const response = await fetch(url)
    const svgString = await response.text()
    return svgString
  } catch (error) {
    console.error('Error fetching SVG:', error)
    throw error
  }
}

export function checkIfDuplicate(arr, checkName, checkType) {
  console.log('type')
  const isDuplicate = arr.some((obj) => obj.qr_code_name === checkName && obj.qr_code_extension === checkType)
  return isDuplicate
}
