import { fetchAndConvertPdf } from 'helper/pdfHelper'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function PdfPreview({ s3Link }) {
  const [imageSrc, setImageSrc] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const imgSrc = await fetchAndConvertPdf(s3Link)
        setImageSrc(imgSrc)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [s3Link])

  return (
    <div>
      {loading ? (
        <div className='loader'></div>
      ) : (
        <img src={imageSrc} alt='PDF Preview' width='196' height='224' style={{ border: '6px solid #D9D9D9', display: 'inline-block' }} />
      )}
    </div>
  )
}

export default PdfPreview
PdfPreview.propTypes = {
  s3Link: PropTypes.string
}
