import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ColorPalette } from 'view/mycolorpalettes/colorpalette'
import { deleteColorPallete } from 'helper/apiHelper'
import toast from 'react-hot-toast'
import '../index.scss'
import { getUserIdFromLocalStorage } from 'config/apiConfig'

const MySavedColorPalettes = ({ colorPalettes, onUpdatePalette, fetchData }) => {
  const [editablePalettes, setEditablePalettes] = useState([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [currentDeletePalette, setCurrentDeletePalette] = useState(null)
  const userId = getUserIdFromLocalStorage()

  useEffect(() => {
    if (Array.isArray(colorPalettes)) {
      const initialEditablePalettes = colorPalettes.map((palette) => ({
        id: palette.color_palette_id,
        name: palette.color_palette_name,
        values: JSON.parse(palette.color_palette_values)
      }))
      setEditablePalettes(initialEditablePalettes)
    }
  }, [colorPalettes])

  const handleSaveClick = async (paletteId) => {
    const updatedPalette = editablePalettes.find((palette) => palette.id === paletteId)

    if (updatedPalette) {
      const updatedPaletteData = JSON.stringify(updatedPalette.values)

      try {
        await onUpdatePalette({
          user_id: userId,
          color_palette_id: paletteId,
          color_palette_name: updatedPalette.name,
          color_palette_values: updatedPaletteData
        })

        // You can choose to update the state or handle it as needed
      } catch (error) {
        // Handle unexpected errors, show a message, etc.
      }
    }
  }

  const handleDeleteClick = (colorPaletteId) => {
    setCurrentDeletePalette(colorPaletteId)
    setShowDeleteConfirmation(true)
  }

  const handleDeleteConfirmation = async () => {
    try {
      await deleteColorPallete(currentDeletePalette)
      fetchData()
      toast.success('Color Palette deleted successfully.')
    } catch (error) {
      // Handle unexpected errors, show a message, etc.
    } finally {
      setShowDeleteConfirmation(false)
      setCurrentDeletePalette(null)
    }
  }

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false)
    setCurrentDeletePalette(null)
  }
  const addNewColorToPalette = (editablePalette) => {
    const editablePaletteIndex = editablePalettes.findIndex((palette) => palette.id === editablePalette.id)
    if (editablePaletteIndex !== -1) {
      const updatedValues = [
        ...(editablePalette ? editablePalette.values : []),
        {
          id: editablePalette.values.length + 1,
          title: 'Color' + (editablePalette.values.length + 1),
          color_code: '#FFFFFF'
        }
      ]

      const updatedEditablePalettes = [...editablePalettes]
      updatedEditablePalettes[editablePaletteIndex].values = updatedValues

      setEditablePalettes(updatedEditablePalettes)
    }
  }
  return (
    <div className='saved-color-palette'>
      {colorPalettes.length > 0 && <div className='create-new-color'>My Saved Color Palettes</div>}
      <div className='palettes-name-container'>
        {Array.isArray(colorPalettes) ? (
          colorPalettes?.map((colorPalette, index) => {
            const editablePalette = editablePalettes.find((palette) => palette.id === colorPalette.color_palette_id)

            return (
              <div key={colorPalette.color_palette_id} className='color-palette'>
                <div className='palettes-container-title'>
                  <input
                    type='text'
                    value={editablePalette ? editablePalette.name : ''}
                    onChange={(e) => {
                      const updatedEditablePalettes = [...editablePalettes]
                      updatedEditablePalettes[index].name = e.target.value
                      setEditablePalettes(updatedEditablePalettes)
                    }}
                  />
                </div>
                <div className='color-container-lib'>
                  <div className='color-container-item'>
                    <ColorPalette
                      data={editablePalette ? editablePalette.values : []}
                      onClickColor={() => addNewColorToPalette(editablePalette)}
                      handleColorPalatte={(event, id, isPaletteControl) => {
                        const updatedValues = (editablePalette ? editablePalette.values : []).map((item) => {
                          if (item.id === id) {
                            return {
                              ...item,
                              color_code: isPaletteControl ? event : event.target.value
                            }
                          }
                          return item
                        })

                        const updatedEditablePalettes = [...editablePalettes]
                        updatedEditablePalettes[index].values = updatedValues
                        setEditablePalettes(updatedEditablePalettes)
                      }}
                      handleColorInput={(event, id, isPaletteControl) => {
                        const updatedValues = (editablePalette ? editablePalette.values : []).map((item) => {
                          if (item.id === id) {
                            return {
                              ...item,
                              color_code: isPaletteControl ? event : event.target.value
                            }
                          }
                          return item
                        })

                        const updatedEditablePalettes = [...editablePalettes]
                        updatedEditablePalettes[index].values = updatedValues
                        setEditablePalettes(updatedEditablePalettes)
                      }}
                    />
                  </div>
                </div>
                <div className='buttons-container'>
                  <button className='update-button' onClick={() => handleSaveClick(colorPalette.color_palette_id)}>
                    Update
                  </button>
                  <button className='update-button' onClick={() => handleDeleteClick(colorPalette.color_palette_id)}>
                    Delete
                  </button>
                </div>
              </div>
            )
          })
        ) : (
          <div className='no-color-palettes'></div>
        )}
      </div>
      {showDeleteConfirmation && (
        <div className='modal'>
          <div className='modal-content'>
            <p>Are you sure you want to delete?</p>
            <button className='delete-button' onClick={handleDeleteConfirmation}>
              Yes
            </button>
            <button className='cancel-button' onClick={handleCancelDelete}>
              No
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

MySavedColorPalettes.propTypes = {
  colorPalettes: PropTypes.any,
  onUpdatePalette: PropTypes.func,
  fetchData: PropTypes.func
}

export default MySavedColorPalettes
