import React, { useEffect, useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import Layout from 'view/layout/Header'
import Cookies from 'js-cookie'
import { setCookie } from 'common/helpers'
const LoginPage = () => {
  const [newUser, setNewUser] = useState(false)
  const [errors, setErrors] = useState({})
  const [rememberMe, setRememberMe] = useState(false)
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  })
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked
    setRememberMe(isChecked)

    if (!isChecked) {
      // If checkbox is unchecked, remove the 'rememberMe' cookie
      Cookies.remove('rememberMe')
    } else {
      // If checkbox is checked, set the 'rememberMe' cookie with a 7-day expiration
      Cookies.set('rememberMe', 'true', { expires: 7 })
    }
  }

  // useEffect to read 'rememberMe' cookie and update state on component mount
  useEffect(() => {
    const rememberMeCookie = Cookies.get('rememberMe')
    if (rememberMeCookie) {
      setRememberMe(true)
    }
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }
  const checkLocalUser = () => {
    const check = JSON.parse(localStorage.getItem('username'))
    if (check) {
      navigate('/dashboard')
    }
  }

  //newuser function
  useEffect(() => {
    checkLocalUser()
    const storedUsername = Cookies.get('username')

    setFormData({
      ...formData,
      username: storedUsername
    })

    document.title = 'Login'
    const params = new URLSearchParams(window.location.search)
    if (params.get('register') === 'success') {
      setNewUser(true)
    }
  }, [])

  //validation
  const handleSubmit = async (e) => {
    e.preventDefault()
    const validationErrors = validateForm(formData)

    if (Object.keys(validationErrors).length === 0) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL + '/api/user-login'
        const headers = {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY
        }

        const response = await axios.post(apiUrl, formData, { headers: headers })
        if (rememberMe) {
          Cookies.set('username', formData.username)
        } else {
          Cookies.remove('username')
        }
        // if (typeof response.data.user_id === 'string') localStorage.setItem('user_id', response.data.user_id)
        localStorage.setItem('user_id', response.data.user_id)
        const tokenJSON = JSON.stringify(response.data.token)
        const userrole = response.data.user_role
        // Store in cookies for editor reference
        setCookie('user_id', response.data.user_id, 1)
        setCookie('token', tokenJSON)
        setCookie('username', formData.username)
        setCookie('user_role', response.data.user_role)

        // Store the JSON string in localStorage
        localStorage.setItem('token', tokenJSON)
        localStorage.setItem('username', JSON.stringify(formData.username))
        localStorage.setItem('user_role', userrole)
        setFormData({
          username: '',
          password: ''
        })
        setErrors({})
        toast.success('Logged in Successfully!')
        navigate(`/dashboard`)
      } catch (error) {
        console.error('Error:', error)
        toast.error('Incorrect password or email please check again')
      }
    } else {
      setErrors(validationErrors)
    }
  }

  const validateForm = (data) => {
    const errors = {}

    if (!data.username.trim()) {
      errors.username = 'Username is required'
    }

    if (!data.password.trim()) {
      errors.password = 'password is required'
    }

    return errors
  }

  let navigate = useNavigate()

  const passwordResetClick = () => {
    navigate('/send-forgot-password-email')
  }

  const registerClick = () => {
    navigate('/register')
  }

  return (
    <div className='container'>
      <Layout />
      <div className='container1'>
        {newUser ? (
          <div className='container-left'>
            <h1 className='welcome'>You’re registered</h1>
            <div className='first-line'>Now that you’ve created your unique username and password, you’re ready to begin.</div>
          </div>
        ) : (
          <div className='container-left'>
            <h1 className='welcome'>Welcome back</h1>
            <div className='first-line'>Log in to start a new project.</div>
          </div>
        )}

        <div className='container-right'>
          <form className='user-form' onSubmit={handleSubmit}>
            <div className='input-box'>
              <input type='text' placeholder='username' name='username' value={formData.username} onChange={handleInputChange} />
              {errors.username && <span className='error-color'>{errors.username}</span>}
            </div>
            <div className='input-box'>
              <input type='password' placeholder='Password' name='password' value={formData.password} onChange={handleInputChange} />
              {errors.password && <span className='error-color'>{errors.password}</span>}
            </div>

            <div className='remember_box'>
              <label className='main'>
                <input type='checkbox' checked={rememberMe} onChange={handleCheckboxChange} />
                <span className='checkmark-remember'></span>
              </label>
              <label>Remember me</label>
            </div>
            <button className='login-button'>Login</button>
            <div className='login-page-link'>
              <span onClick={passwordResetClick}>Forgot Password</span>
              <span onClick={registerClick}>Register here</span>
            </div>
          </form>
        </div>
        {/* Sign in form */}
        <div>
          <div className='container3'>
            <span className='terms'>
              <p>40 Knots™ 888 Prospect Street Suite 200 La Jolla, California 92037 USA</p>
              <p>
                <b>Terms of Use | Privacy Policy | Cookie Policy</b>
              </p>
              <p>© 2023 Corsaire Corporation™. All rights reserved.</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
