// QRCodeGenerator.js
import React, { useRef, useState } from 'react'
import QRCode from 'qrcode.react'
import './index.scss' // Import your custom styles for the component
import toast from 'react-hot-toast'
import options from 'assets/images/options.png'
import arrow from 'assets/images/arrow.png'
import arrowopen from 'assets/images/arrow-open.png'
import PropTypes from 'prop-types'
import { checkIfDuplicate, getContrastRatio, handleSavePNG, handleSaveSVG, isValidHexColor } from './helper'

function QRCodeGenerator({ qrData, fetchImageData }) {
  const [text, setText] = useState('')
  const [foreground, setForeground] = useState('#000000')
  const [background, setBackground] = useState('#ffffff')
  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef(null)

  const handleGenerate = async (type) => {
    if (!text) {
      toast.error('Please enter text to encode.')
      return
    }
    if (!isValidHexColor(foreground)) {
      toast.error('Enter a valid hex code for the foreground color.')
      return
    }

    // Check if background is a valid hex color
    if (!isValidHexColor(background)) {
      toast.error('Enter a valid hex code for the background color.')
      return
    }
    const contrastRatio = getContrastRatio(foreground, background)

    const isLowContrast = contrastRatio < 3.5
    if (isLowContrast) {
      toast.error('Low contrast warning: Opt for colors with better contrast for improved readability.')
      return
    } else {
      if (type === 'png') {
        if (checkIfDuplicate(qrData, text, 'png')) {
          toast.error('A QR code with the identical URL encoded is present in PNG format.')
        } else {
          await handleSavePNG(text, foreground, background)
            .then(() => {
              fetchImageData()
            })
            .catch((error) => {
              // Handle errors if any
              console.error(error)
            })
        }
      } else if (type === 'svg') {
        if (checkIfDuplicate(qrData, text, 'svg')) {
          toast.error('A QR code with the identical URL encoded is present in SVG format.')
        } else {
          await handleSaveSVG(text, foreground, background)
            .then(() => {
              fetchImageData()

              console.log('Text after reset:', text)
            })
            .catch((error) => {
              // Handle errors if any
              console.error(error)
            })
        }
      }
    }
  }
  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className='color-picker-container'>
      <div className='qr-code-generator-container'>
        <div className='right-section-title'>QR Code Generator</div>
        <h4>Submit Url</h4>
        <div className='text-input-container'>
          <input type='text' value={text} onChange={(e) => setText(e.target.value)} placeholder='Enter url to encode' />
        </div>

        {text && (
          <>
            <div className='qr-code-preview'>
              <div ref={containerRef}>
                <QRCode value={text} size={128} bgColor={background} fgColor={foreground} includeMargin={true} />
              </div>
              <div className='save-button'>
                <button className='button-generate' onClick={() => handleGenerate('png')}>
                  Save as png
                </button>

                <button className='button-generate' onClick={() => handleGenerate('svg')}>
                  Save as svg
                </button>
              </div>
            </div>
          </>
        )}
        {!text && (
          <div className='no-qr-container'>
            <h6>Enter a URL to begin</h6>
          </div>
        )}
      </div>
      <div className='option-wrapper'>
        <div className='options' onClick={handleClick}>
          {'Options'} <img src={options} />
          <img src={isOpen ? arrowopen : arrow} className={isOpen ? 'open-styles' : 'closed-styles'} />
        </div>

        {isOpen && (
          <div className='options-container'>
            <h4>Colors</h4>
            <h5>Background Color</h5>
            <input type='text' id='background' value={background} onChange={(e) => setBackground(e.target.value)} />
            <h5>Dots Color</h5>
            <input type='text' id='foreground' value={foreground} onChange={(e) => setForeground(e.target.value)} />
          </div>
        )}
      </div>
    </div>
  )
}
QRCodeGenerator.propTypes = {
  qrData: PropTypes.array.isRequired,
  fetchImageData: PropTypes.func.isRequired
}
export default QRCodeGenerator
