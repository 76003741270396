/* eslint-disable no-unused-vars */
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import './App.scss'
import { AuthProvider } from 'auth/AuthContext'
import React, { Fragment } from 'react'
import PublicRoute from 'route/PublicRoute'
import PrivateRoute from 'route/PrivateRoute'

// Static pages below
import LandingPage from './view/landing'
import LoginPage from './view/login'
import TempUser from './view/tempuser'
import RegisterUsername from './view/registerusername'
import RegisterPassword from './view/registerpassword'

// private
import DashBoard from './view/dashboard'
import ColorPalettes from './view/mycolorpalettes'
import MyFonts from 'view/myfont'
import MyLogo from 'view/mylogos'
import MyImages from 'view/myimages'
import MyCharts from 'view/mycharts'
import MyReference from './view/myreferences/myreference'
import MyImportantSafetyInformation from 'view/myisi'
import MyPrescribingInformation from 'view/mypi'
import MyISIDetails from 'view/myisi/MyISIDetails'
import MyProjects from 'view/myprojects'
// import templateUpload from 'view/template-upload'
import selectTemplate from 'view/Explore-Templates/Selected-Template'
import SendEmail from 'view/forgotpassword/sendemail'
import ForgotPassword from 'view/forgotpassword'
import MyQRCodes from 'view/myqrcodes'
// import MyCharts from './static-demonstration/pages/Dashboard/MyCharts'
// import MyLogos from './static-demonstration/pages/Dashboard/MyLogo'
// import MyImages from './static-demonstration/pages/Dashboard/MyImages'
// import MyBrandLib from './static-demonstration/pages/Dashboard/MyBrandLib'
// import ColorPalettes from './static-demonstration/pages/Dashboard/ColorPalettes'

// import MyProjects from './static-demonstration/pages/Dashboard/MyProjects'
// import MyImportantSafetyInformation from './static-demonstration/pages/Dashboard/MyImportantSafetyInformation'
// import MySavedISI from './static-demonstration/pages/Dashboard/MySavedISI'
// import MyReferences from './static-demonstration/pages/Dashboard/MyReferences'
// import MyReferenceAuthor from './static-demonstration/pages/Dashboard/MyReferenceAuthor'
// import Editor from './static-demonstration/pages/Editor'
// import MyReferenceClaim from './static-demonstration/pages/Dashboard/MyReferenceClaim'

// import MySavedPI from './static-demonstration/pages/Dashboard/MySavedPI'
// import MyISIDetails from './static-demonstration/pages/Dashboard/MyISIDetails'
// import BasicScreen from './static-demonstration/pages/Editor/BasicScreen'
// import TempNameEmail from './static-demonstration/pages/Login/TempNameEmail'

function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <BrowserRouter>
          <Fragment>
            <Routes>
              {/* Public Routes */}
              <Route path='/' element={<PublicRoute component={LandingPage} />} />
              <Route path='/login' element={<PublicRoute component={LoginPage} />} />
              <Route path='/register' element={<PublicRoute component={RegisterUsername} />} />
              <Route path='/password-reset' element={<PublicRoute component={RegisterPassword} />} />
              {/* <Route path='/template-upload' element={<PublicRoute component={templateUpload} />} /> */}
              <Route path='/send-forgot-password-email' element={<PublicRoute component={SendEmail} />} />
              <Route path='/forgot-password' element={<PublicRoute component={ForgotPassword} />} />

              {/* Private Routes */}
              <Route path='/tempuser' element={<PrivateRoute component={TempUser} />} />
              <Route path='/dashboard' element={<PrivateRoute component={DashBoard} />} />
              <Route path='/myprojects' element={<PrivateRoute component={MyProjects} />} />
              <Route path='/mycolor-palettes' element={<PrivateRoute component={ColorPalettes} />} />
              <Route path='/myfonts' element={<PrivateRoute component={MyFonts} />} />
              <Route path='/mylogos' element={<PrivateRoute component={MyLogo} />} />
              <Route path='/mycharts' element={<PrivateRoute component={MyCharts} />} />
              <Route path='/myqrcodes' element={<PrivateRoute component={MyQRCodes} />} />
              <Route path='/myimages' element={<PrivateRoute component={MyImages} />} />
              <Route path='/myimportant-saftey-information' element={<PrivateRoute component={MyImportantSafetyInformation} />} />
              <Route path='/my-prescribing-information' element={<PrivateRoute component={MyPrescribingInformation} />} />
              <Route path='/myreferences' element={<PrivateRoute component={MyReference} />} />
              <Route path='/myimportant-saftey-information' element={<PrivateRoute component={MyImportantSafetyInformation} />} />
              <Route path='/myimportant-saftey-information/details/:id' element={<PrivateRoute component={MyISIDetails} />} />
              <Route path='/templates' element={<PrivateRoute component={selectTemplate} />} />
            </Routes>
          </Fragment>
        </BrowserRouter>

        {/* TODO : Move these below routes to private vs public respectively as required */}
        {/*  <BrowserRouter>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/login' element={<LoginPage />} />
              <Route path='/tempuser' element={<PublicRoute component={TempNameEmail} />} />
              <Route path='/register' element={<PublicRoute component={RegisterUsername} />} />
              <Route path='/password-reset' element={<PublicRoute component={RegisterPassword} />} />

            <ProtectedRoute path='/dashboard' element={<Dashboard />} />
           <Route path='/myprojects' element={<PrivateRoute component={MyProjects} />} />
            <Route path='/myfonts' element={<MyBrandLib />} />
            <Route path='/myproject' element={<ColorPalettes />} />
            <Route path='/mycharts' element={<MyCharts />} />
            <Route path='/mylogos' element={<MyLogos />} />
            <Route path='/myimages' element={<MyImages />} />
            <Route path='/register' element={<RegisterUsername />} />
            <Route path='/tempuser' element={<TempNameEmail />} />
            <Route path='/password-reset' element={<RegisterPassword />} />
            <Route path='/register-success' element={<RegisterSuccess />} />

            <Route path='/myprojects' element={<MyProjects />} />
            <Route path='/my-important-saftey-information' element={<MyImportantSafetyInformation />} />
            <Route path='/my-saved-isi' element={<MySavedISI />} />
            <Route path='/my-isi-details' element={<MyISIDetails />} />
            <Route path='/myreferences' element={<MyReferences />} />
            <Route path='/reference-author' element={<MyReferenceAuthor />} />
            <Route path='/editor' element={<Editor />} />
            <Route path='/reference-claim' element={<MyReferenceClaim />} />
            <Route path='/my-prescribing-information' element={<MyPrescribingInformation />} />
            <Route path='/my-saved-pi' element={<MySavedPI />} />
            <Route path='/editor-screen' element={<BasicScreen />} />
          </Routes>
        </BrowserRouter> */}
        <Toaster />
      </AuthProvider>
    </div>
  )
}

export default App
