// s3Config.js (JavaScript)
import AWS from 'aws-sdk'

const s3 = new AWS.S3({
  region: process.env.REACT_APP_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
})

export default s3
export const bucketName = process.env.REACT_APP_BUCKET_NAME
