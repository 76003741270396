import React, { useState } from 'react'
import Rectangle from 'assets/images/myprojects/Rectangle.png'
import './index.scss'
import { handleLaunchEditor } from 'common/helpers'
import { getObjectURLSync } from 'helper/s3Helper'
import { getUserIdFromLocalStorage } from 'config/apiConfig'

const Archives = ({
  project,
  createNewProjectVersion,
  projectVersionsList,
  deleteProjectVersion,
  setProjectThumbnailURL,
  fetchProjectVersionThumbnail
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const user_id = getUserIdFromLocalStorage()
  const [previewPopups, setPreviewPopups] = useState(
    projectVersionsList?.map(() => false) || [] // Initialize preview states based on version length
  )

  const handleToggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  const handleOpenProject = (versionId) => {
    handleLaunchEditor(project.project_id, versionId)
  }

  const handleCreateVersion = async (sourceVersionID) => {
    const response = await createNewProjectVersion(project.project_id, sourceVersionID)

    setProjectThumbnailURL(null)
    const url = await fetchProjectVersionThumbnail(project.project_id, response.versionId)
    setTimeout(() => {
      setProjectThumbnailURL(url)
    }, 1000)
  }

  const handleDeleteProjectVersion = (versionID) => {
    if (window.confirm(`Are you sure you want to delete project version ${versionID}?`)) {
      deleteProjectVersion(project.project_id, versionID)
    } else {
      // Handle user canceling the confirmation
      console.log('Project version deletion canceled.') // Example for logging
    }
  }

  const togglePreviewPopup = (index) => {
    setPreviewPopups((prevPopups) => {
      const updatedPopups = [...prevPopups]
      updatedPopups[index] = !updatedPopups[index]
      return updatedPopups
    })
  }

  return (
    <div className='Versioning'>
      <div className='reveal'>
        <span onClick={handleToggleVisibility}>
          {isVisible ? 'Hide Archive' : 'Reveal Archive'}
          <img src={Rectangle} alt='' className={`rotate-img ${isVisible ? 'rotated' : ''}`} />
        </span>
        {isVisible && (
          <>
            <div className='versions'>
              {!projectVersionsList[0]?.version_ids ? (
                <div className='version-item'>
                  <div className='proj-name-versions'>No archives created yet</div>
                </div>
              ) : (
                projectVersionsList.map((version, versionIndex) => {
                  const versionIdsArray = version.version_ids ? version.version_ids.split(',').reverse().map(Number) : [1]

                  if (versionIdsArray.length == 1) {
                    return (
                      <div key={versionIndex} className='version-item'>
                        <div className='proj-name-versions'>No archives created yet</div>
                      </div>
                    )
                  }

                  return versionIdsArray.map((versionId, idIndex) => {
                    const isPopupOpen = previewPopups[versionIndex * versionIdsArray.length + idIndex] // Calculate index based on version and versionId

                    return (
                      <div key={versionId} className='version-item'>
                        <div className='proj-name-versions'>
                          {version.project_name} - v{versionId}
                        </div>
                        <div className='open-proj'>
                          <button onClick={() => handleOpenProject(versionId)} className='open-proj-button'>
                            Open Project
                          </button>
                        </div>
                        <div onClick={() => handleCreateVersion(versionId)} className='create-version'>
                          Create a new version
                        </div>
                        <div className='preview'>
                          {isPopupOpen && (
                            <div className='preview-popup'>
                              <img
                                src={getObjectURLSync(`${user_id}/projects/project${project.project_id}/version${versionId}/page-1.png`)}
                              />
                            </div>
                          )}
                          <p onClick={() => togglePreviewPopup(versionIndex * versionIdsArray.length + idIndex)} className='preview-text'>
                            Preview
                          </p>
                        </div>
                        {versionIdsArray.length > 1 && (
                          <div onClick={() => handleDeleteProjectVersion(versionId)} className='delete'>
                            Delete
                          </div>
                        )}
                      </div>
                    )
                  })
                })
              )}
            </div>
            <div className='dotted-hr'></div>
          </>
        )}
      </div>
    </div>
  )
}

export default Archives
