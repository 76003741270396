import React from 'react'
import PropTypes from 'prop-types'

const AdjustFontComponent = ({
  decreaseFontSize,
  fontSize,
  increaseFontSize,
  isUnderlined,
  isStrikedOut,
  toggleUnderline,
  toggleStrikeout
}) => {
  return (
    <p className='adjust-font'>
      <span onClick={decreaseFontSize}>-</span> {fontSize} <span onClick={increaseFontSize}>+</span>
      {isUnderlined ? <u onClick={toggleUnderline}>U</u> : <span onClick={toggleUnderline}>U</span>}
      {isStrikedOut ? <s onClick={toggleStrikeout}>S</s> : <span onClick={toggleStrikeout}>S</span>}
    </p>
  )
}

export default AdjustFontComponent

AdjustFontComponent.propTypes = {
  decreaseFontSize: PropTypes.func,
  fontSize: PropTypes.number,
  increaseFontSize: PropTypes.func,
  isUnderlined: PropTypes.bool.isRequired,
  isStrikedOut: PropTypes.bool.isRequired,
  toggleUnderline: PropTypes.func,
  toggleStrikeout: PropTypes.func
}
