import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { pdfjs } from 'react-pdf'
import './index.scss'
import PDFPop from 'components/pdf-popup'
const Claim = ({ listData }) => {
  const [popupUrl, setPopupUrl] = useState(null)
  const [numPages, setNumPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingPDF, setIsLoadingPDF] = useState(false)

  const openPopup = (url, annotation) => {
    const pageNumber = annotation ? parseInt(annotation?.match(/\d+/), 10) : 1
    if (!isNaN(pageNumber) && pageNumber >= 1) {
      setCurrentPage(pageNumber)
    } else {
      setCurrentPage(1)
    }
    setPopupUrl(url)
  }

  const closePopup = () => {
    setPopupUrl(null)
  }

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  //to get year only from date
  function getPublicationYear(dateString) {
    const publicationDate = new Date(dateString)
    return publicationDate.getFullYear()
  }

  // Group the listData by claim
  const groupedData = groupDataByClaim(listData)
  // Function to group data by claim
  function groupDataByClaim(data) {
    const groupedData = {}
    data.forEach((item) => {
      const claimText = item.claim
      if (!groupedData[claimText]) {
        groupedData[claimText] = []
      }
      groupedData[claimText].push(item)
    })
    return groupedData
  }
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  useEffect(() => {
    if (popupUrl) {
      setIsLoadingPDF(true)
      pdfjs.getDocument(popupUrl).promise.then((pdfDocument) => {
        setNumPages(pdfDocument.numPages)
        setIsLoadingPDF(false)
      })
    }
  }, [popupUrl])

  return (
    <div className='claim-container'>
      {Object.keys(groupedData).map((claimText, index) => {
        const claimData = groupedData[claimText]
        return (
          <React.Fragment key={index}>
            <div className='claim-heading'>&ldquo;{claimText}&rdquo;</div>
            <div className='claim-container-one'>
              <h3>PUBLICATION(S)</h3>

              {claimData.map(({ file_url, author_name, file_name, publication_date, annotation }, innerIndex) => (
                <div className='claim-box' key={innerIndex}>
                  <p>
                    {file_name && file_name.toString()}
                    {file_name && (publication_date || author_name) && ', '}
                    {author_name && author_name.toString()}
                    {author_name && publication_date && ', '}
                    {publication_date ? getPublicationYear(publication_date) : ''}
                  </p>

                  {file_url && <button onClick={() => openPopup(file_url, annotation)}>See Publication</button>}
                </div>
              ))}
            </div>
          </React.Fragment>
        )
      })}
      {popupUrl && (
        <PDFPop
          popupUrl={popupUrl}
          currentPage={currentPage}
          numPages={numPages}
          isLoadingPDF={isLoadingPDF}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          closePopup={closePopup}
        />
      )}
    </div>
  )
}

Claim.propTypes = {
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      claim: PropTypes.string,
      author_name: PropTypes.string,
      file_name: PropTypes.string,
      publication_date: PropTypes.string,
      file_url: PropTypes.string
    })
  ).isRequired
}

export default Claim
