import { useState } from 'react'
import axios from 'axios'
import { getDeleteProjectAPIURL, getDisableProjectAPIURL, getProjectList, getUserIdFromLocalStorage, headers } from 'config/apiConfig'
import toast from 'react-hot-toast'
import { deleteProjectFolderFromS3 } from 'helper/s3Helper'

export const useProjectList = () => {
  const [projectList, setProjectList] = useState([])
  const user_id = getUserIdFromLocalStorage()

  const fetchProjectList = async () => {
    try {
      const user_id = getUserIdFromLocalStorage()

      const apiUrl = getProjectList(user_id)
      const response = await axios.get(apiUrl, { headers })

      const formattedProjects = response.data.map((project) => ({
        ...project,
        last_updated: new Date(project.last_updated).toLocaleDateString()
      }))

      setProjectList(formattedProjects)
    } catch (error) {
      // console.error('Error fetching project list:', error)
    }
  }

  const deleteProject = async (projectID) => {
    const apiUrl = getDeleteProjectAPIURL(user_id, projectID)

    return await axios
      .delete(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          // Fonts data saved successfully
          toast.success('Project deleted successfully')
          fetchProjectList(projectID)
          deleteProjectFolderFromS3({ projectID })
          window.location.reload()
          return response.data
        } else {
          toast.error('Something went wrong')
          // Handle errors if the API request fails
          console.error('Failed to delete Project record')
          return false
        }
      })

      .catch((error) => {
        console.error('An error occurred while deleting Project', error)
      })
  }

  const disableProject = async (projectID) => {
    const apiUrl = getDisableProjectAPIURL(user_id, projectID)

    try {
      const response = await axios.patch(apiUrl, {}, { headers })

      if (response.status === 200) {
        toast.success(`Project disabled successfully`)

        fetchProjectList(projectID)
        window.location.reload()
        return response.data
      }
    } catch (error) {
      // Handle the error based on the response structure or network error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error Response:', error.response.data)
        return { error: error.response.data.message || 'Failed to disable project' }
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error Request:', error.request)
        return { error: 'No response received from the server. Please try again later.' }
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message)
        return { error: 'An unexpected error occurred. Please try again.' }
      }
    }
  }

  return { fetchProjectList, projectList, deleteProject, disableProject }
}
