import React from 'react'
import { pdfjs } from 'react-pdf'
import './index.scss'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { checkFileLimit, checkFileSize } from 'config/apiConfig'

const PDFUploader = ({ setPdfData, setFile, fileInputRef, listData, file }) => {
  const handleFileChange = async (e) => {
    const file = e.target.files[0]

    if (file) {
      if (checkFileSize(file.size)) {
        if (checkFileLimit(listData.length)) {
          await processPDF(file)
          setFile(file)
        } else {
          toast.error(`You cannot upload more than 50 files into your library.`)
        }
      } else {
        toast.error('Please upload a file with a size of 50MB or less.')
      }
    }
  }

  async function processPDF(file) {
    try {
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
      const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise
      const numPages = pdf.numPages
      let pdfText = ''

      for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i)
        const textContent = await page.getTextContent()
        pdfText += textContent.items.map((item) => item.str).join(' ')
      }

      //removing extra spaces after extraction
      // pdfText = pdfText.replace(/\s{2,}/g, ' ')
      const extractedInfo = extractInfoFromText(pdfText)
      setPdfData(extractedInfo)
    } catch (error) {
      console.error('Error processing PDF:', error)
      throw error
    }
  }

  function extractInfoFromText(text) {
    // Validation and extraction logic
    const conditonOne = '([ ]{2}.*?[ ]{2})(|$)'
    const conditonTwo = '(.*?[ ]{2})(|$)'
    // const conditonTheree = '([ ]{2}.*?[ ]{2}.*?)(|$)'
    const authorNameRegex = new RegExp(`Author:(${conditonOne}|${conditonTwo})`)
    const claimRegex = new RegExp(`Claim:(${conditonOne}|${conditonTwo})`)
    const dateRegex = new RegExp(`Date:(${conditonOne}|${conditonTwo})`)
    const annotationRegex = new RegExp(`Annotation:(${conditonOne}|${conditonTwo})`)
    const citationRegx = new RegExp(`Citation:(${conditonOne}|${conditonTwo})`)

    // const authorNameRegex = /Author:\s*(.*?)(\n|$)/
    // const claimRegex = /Claim:\s*(.*?)(\n|$)/
    // const dateRegex = /Date:\s*(.*?)(\n|$)/
    // const annotationRegex = /Annotation:\s*(.*?)(\n|$)/

    const authorMatch = text.match(authorNameRegex)
    const claimMatch = text.match(claimRegex)
    const dateMatch = text.match(dateRegex)
    const annotationMatch = text.match(annotationRegex)
    const citationMatch = text.match(citationRegx)

    const extractedInfo = {
      author_name: authorMatch ? authorMatch[1] : '',
      claim: claimMatch ? claimMatch[1] : '',
      publication_date: dateMatch ? dateMatch[1] : '',
      annotation: annotationMatch ? annotationMatch[1] : '',
      citation: citationMatch ? citationMatch[1] : ''
    }

    return extractedInfo
  }

  return (
    <div className='upload-file'>
      <div className='upload-button-reference'>
        <input
          type='file'
          accept='.pdf'
          name='file-1[]'
          id='file-1'
          className='inputfile inputfile-1'
          data-multiple-caption='{count} files selected'
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <label htmlFor='file-1'>
          <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'>
            <path
              d='M24.1875 12.55C23.3375 8.2375 19.55 5 15 5C11.3875 5 8.25 7.05 6.6875 10.05C2.925 10.45 0 13.6375 0 17.5C0 21.6375 3.3625 25 7.5 25H23.75C27.2 25 30 22.2 30 18.75C30 15.45 27.4375 12.775 24.1875 12.55ZM23.75 22.5H7.5C4.7375 22.5 2.5 20.2625 2.5 17.5C2.5 14.9375 4.4125 12.8 6.95 12.5375L8.2875 12.4L8.9125 11.2125C10.1 8.925 12.425 7.5 15 7.5C18.275 7.5 21.1 9.825 21.7375 13.0375L22.1125 14.9125L24.025 15.05C25.975 15.175 27.5 16.8125 27.5 18.75C27.5 20.8125 25.8125 22.5 23.75 22.5ZM10 16.25H13.1875V20H16.8125V16.25H20L15 11.25L10 16.25Z'
              fill='black'
            />
          </svg>
          <span>Add Publication</span>
        </label>
      </div>
      <p>{file?.name}</p>
    </div>
  )
}
PDFUploader.propTypes = {
  setPdfData: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  listData: PropTypes.array.isRequired,
  file: PropTypes.instanceOf(File)
}
export default PDFUploader
