import { useState } from 'react'
import axios from 'axios'
import {
  getDeleteProjectVersionAPIURL,
  getDisableProjectVersionAPIURL,
  getProjectVersionsList,
  getSaveProjectVersionAPIURL,
  getUserIdFromLocalStorage,
  headers
} from 'config/apiConfig'
import toast from 'react-hot-toast'
import { copyFilesFromSourceToTarget, deleteVersionFolderFromS3, getObjectURL } from 'helper/s3Helper'

export const useProjectVersionList = () => {
  const [isLoading, setIsLoading] = useState([])
  const [projectVersionsList, setProjectVersionsList] = useState([])
  const user_id = getUserIdFromLocalStorage()

  const fetchProjectList = async (projectID) => {
    try {
      if (user_id && projectID) {
        const apiUrl = getProjectVersionsList(user_id, projectID)
        const response = await axios.get(apiUrl, { headers })
        const formattedProjects = response.data.map((project) => ({
          ...project
          //   last_updated: new Date(project.last_updated).toLocaleDateString()
        }))
        setProjectVersionsList(formattedProjects)
      } else {
        throw new Error('User ID or Project ID is missing')
      }
    } catch (error) {
      console.error('Error fetching project list:', error)
      // Optionally handle the error here or rethrow it
    }
  }

  const fetchProjectVersionThumbnail = async (projectID, versionID) => {
    try {
      const user_id = getUserIdFromLocalStorage()
      const s3Key = `${user_id}/projects/project${projectID}/version${versionID}/page-1.png`
      const presignedUrl = await getObjectURL(s3Key)
      return presignedUrl
    } catch (error) {
      console.error('Error fetching thumbnails:', error)
    }
  }

  const createNewProjectVersion = async (projectID, sourceVersionID) => {
    setIsLoading(true)
    const apiUrl = getSaveProjectVersionAPIURL(user_id, projectID)

    return await axios
      .post(apiUrl, {}, { headers })
      .then(async (response) => {
        if (response.status === 200) {
          fetchProjectList(projectID)

          await copyFilesFromSourceToTarget({ projectID, sourceVersionID, targetVersionID: response.data.versionId })
          // Fonts data saved successfully
          toast.success(`Project version-${response.data.versionId} created successfully`)
          return response.data
        } else {
          toast.error('Something went wrong')
          // Handle errors if the API request fails
          console.error('Failed to create Project Version record')
          return false
        }
      })

      .catch((error) => {
        console.error('An error occurred while creating Project', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const deleteProjectVersion = async (projectID, versionID) => {
    const apiUrl = getDeleteProjectVersionAPIURL(user_id, projectID, versionID)

    return await axios
      .delete(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          // Fonts data saved successfully
          console.log('Project version deleted successfully.')
          toast.success('Project version deleted successfully')
          fetchProjectList(projectID)
          deleteVersionFolderFromS3({ projectID, versionID })
          return response.data
        } else {
          toast.error('Something went wrong')
          // Handle errors if the API request fails
          console.error('Failed to delete Project Version record')
          return false
        }
      })

      .catch((error) => {
        console.error('An error occurred while deleting Project', error)
      })
  }

  const disableProjectVersion = async (projectID, versionID) => {
    const apiUrl = getDisableProjectVersionAPIURL(user_id, projectID, versionID)

    try {
      const response = await axios.patch(apiUrl, {}, { headers })

      if (response.status === 200) {
        toast.success(`Project version disabled successfully`)

        fetchProjectList(projectID)
        // window.location.reload()
        return response.data
      }
    } catch (error) {
      // Handle the error based on the response structure or network error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error Response:', error.response.data)
        return { error: error.response.data.message || 'Failed to disable project' }
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error Request:', error.request)
        return { error: 'No response received from the server. Please try again later.' }
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error:', error.message)
        return { error: 'An unexpected error occurred. Please try again.' }
      }
    }
  }

  const getProjectVersionList = async (projectID) => {
    if (projectID) {
      try {
        await fetchProjectList(projectID)
      } catch (error) {
        console.error('Error getting project version list:', error)
        // Optionally handle the error here or rethrow it
      }
    }
  }

  return {
    getProjectVersionList,
    projectVersionsList,
    createNewProjectVersion,
    deleteProjectVersion,
    disableProjectVersion,
    fetchProjectVersionThumbnail,
    isLoading
  }
}
