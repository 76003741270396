import React, { useEffect, useState } from 'react'
import './index.scss'
import { ColorPalette } from 'view/mycolorpalettes/colorpalette'
import Layout from 'view/layout/Header'
import LeftSection from 'view/layout/Left-Section'
import toast from 'react-hot-toast'
import MySavedColorPalettes from 'view/mycolorpalettes/colorpalette/MySavedColorPalettes'
import { getUserIdFromLocalStorage, updateColorPallete, headers } from 'config/apiConfig'
import { fetchColorPalette, insertColorPalette } from './utilities/colorPallete'
import axios from 'axios'

const ColorPalettes = () => {
  const [colorPalettes, setColorPalettes] = useState([])
  const [lastColor, setLastColor] = useState(null)

  const [palattename, setPalatteName] = useState('')
  const [palatteData, setPalatteData] = useState([
    { id: 1, title: 'Color1', color_code: '#FFFFFF' },
    { id: 2, title: 'Color2', color_code: '#FFFFFF' },
    { id: 3, title: 'Color3', color_code: '#FFFFFF' }
  ])

  const userId = getUserIdFromLocalStorage()
  const maxPalettes = 4
  const sendcolordata = JSON.stringify(palatteData)

  const fetchData = async () => {
    const { response, error } = await fetchColorPalette()
    if (error !== null) {
      console.error('Error fetching data:', error)
    } else {
      setColorPalettes(response.data)
    }
  }

  useEffect(() => {
    document.title = 'My Color Palettes'
    fetchData()
  }, [])

  const handleAddColor = () => {
    const nextColor = lastColor === 'Color4' ? 'Color5' : 'Color4'
    setPalatteData((prevData) => [...prevData, { id: prevData.length + 1, title: nextColor, color_code: '#FFFFFF' }])
    setLastColor(nextColor)
  }

  const handleColorCodeChange = (event, id, isPalatteControl) => {
    const updatedPaletteData = palatteData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          color_code: isPalatteControl ? event : event.target.value
        }
      }
      return item
    })

    setPalatteData(updatedPaletteData)
  }

  const handleUpdatePalette = async (selectedPalette) => {
    if (!selectedPalette || !selectedPalette.color_palette_id) {
      console.error('Invalid selectedPalette object:', selectedPalette)
      return
    }

    const updateEndpoint = updateColorPallete(selectedPalette.color_palette_id)

    const { user_id, color_palette_name, color_palette_values } = selectedPalette

    const updatedPaletteData = {
      user_id,
      color_palette_name,
      color_palette_values
    }

    try {
      await axios.post(updateEndpoint, updatedPaletteData, { headers })
      toast.success('Color Palette updated successfully.')
      fetchData()
    } catch (error) {
      toast.error('An unexpected error occurred:', error)
      // Handle unexpected errors, show a message, etc.
    }
  }

  const savePalette = async () => {
    if (colorPalettes.length === maxPalettes) {
      toast.error('You have reached the maximum limit. You are allowed to create only 4 Color Palettes.')
      return
    }
    const datapalette = {
      user_id: userId,
      color_palette_name: palattename,
      color_palette_values: sendcolordata
    }
    const { response, error } = await insertColorPalette(datapalette)
    if (error !== null) {
      console.error('An error occurred while saving color palette data:', error)
      toast.error('Color palette name is not allowed to be empty')
    } else {
      if (response.status === 200) {
        setPalatteName('')
        setPalatteData([
          { id: 1, title: 'Color1', color_code: '#FFFFFF' },
          { id: 2, title: 'Color2', color_code: '#FFFFFF' },
          { id: 3, title: 'Color3', color_code: '#FFFFFF' }
        ])
        setLastColor(null)
        toast.success('Color Palette saved successfully.')
        fetchData()
      } else {
        // Handle errors if the API request fails
        console.error('Failed to save color palette data.')
        toast.error('An error occurred while saving color palette data')
      }
    }
  }
  // const shouldAllowAdding = colorPalettes && colorPalettes.length < maxPalettes

  // let shouldAllowAdding
  // if (Array.isArray(colorPalettes)) {
  //   if (colorPalettes) {
  //     shouldAllowAdding = colorPalettes.length < maxPalettes
  //   } else {
  //     shouldAllowAdding = false
  //   }
  // } else {
  //   shouldAllowAdding = true
  // }

  return (
    <div className='container-div'>
      <Layout />
      <div className='container-div2'>
        <LeftSection name='palettes' />
        <div className='right-container'>
          <div className='right-section-title'>My Color Palettes</div>
          {/* {shouldAllowAdding && ( */}
          <>
            <div className='create-new-color'>Create a New Color Palette</div>
            <div className='palettes-name-container'>
              <input type='text' placeholder='Palettes Name' value={palattename} onChange={(e) => setPalatteName(e.target.value)} />
              <ColorPalette
                data={palatteData}
                onClickColor={() => handleAddColor()}
                handleColorInput={handleColorCodeChange}
                handleColorPalatte={handleColorCodeChange}
              />
              <div className='save_div_row'>
                <button type='button' onClick={savePalette}>
                  Save
                </button>
              </div>
            </div>
          </>
          {/* )} */}
          <MySavedColorPalettes colorPalettes={colorPalettes} onUpdatePalette={handleUpdatePalette} fetchData={fetchData} />
        </div>
      </div>
    </div>
  )
}

export default ColorPalettes
