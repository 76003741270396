import React from 'react'
import { useNavigate } from 'react-router-dom'
import './index.scss'
import PropTypes from 'prop-types'

const LeftSection = ({ name }) => {
  const navigate = useNavigate()

  const colorClass = 'box'
  const activeClass = 'active'

  const handlePalettessClick = () => {
    navigate('/mycolor-palettes')
  }
  const handlefontsClick = () => {
    navigate('/myfonts')
  }
  const handleLogoClick = () => {
    navigate('/mylogos')
  }
  const handleImageClick = () => {
    navigate('/myimages')
  }
  const handleChartsClick = () => {
    navigate('/mycharts')
  }
  const handleQRClick = () => {
    navigate('/myqrcodes')
  }
  const handleReferenceClick = () => {
    navigate('/myreferences')
  }

  const handleNavigateIsi = () => {
    navigate('/myimportant-saftey-information')
  }
  const handleNavigatePI = () => {
    navigate('/my-prescribing-information')
  }

  return (
    <div className='layout-left-container'>
      <div className={`${colorClass} ${name === 'palettes' ? activeClass : ''}`} onClick={handlePalettessClick}>
        <span>My Color Palettes</span>
      </div>
      <div className={`${colorClass} ${name === 'fonts' ? activeClass : ''}`} onClick={handlefontsClick}>
        <span>My Fonts</span>
      </div>
      <div className={`${colorClass} ${name === 'logos' ? activeClass : ''}`} onClick={handleLogoClick}>
        <span>My Logos</span>
      </div>
      <div className={`${colorClass} ${name === 'images' ? activeClass : ''}`} onClick={handleImageClick}>
        <span>My Images</span>
      </div>
      <div className={`${colorClass} ${name === 'charts' ? activeClass : ''}`} onClick={handleChartsClick}>
        <span>My Charts</span>
      </div>
      <div className={`${colorClass} ${name === 'qr' ? activeClass : ''}`} onClick={handleQRClick}>
        <span>My QR Codes</span>
      </div>
      <div className={`${colorClass} ${name === 'reference' ? activeClass : ''}`} onClick={handleReferenceClick}>
        <span>My References</span>
      </div>
      <div className={`${colorClass} ${name === 'isi' ? activeClass : ''}`} onClick={handleNavigateIsi}>
        <span>My Important Safety Information</span>
      </div>
      <div className={`${colorClass} ${name === 'psi' ? activeClass : ''}`} onClick={handleNavigatePI}>
        <span>My Prescribing Information</span>
      </div>
    </div>
  )
}
LeftSection.propTypes = {
  name: PropTypes.string
}

export default LeftSection
