import React, { useEffect, useState } from 'react'
import Layout from 'view/layout/Header'
import LeftSection from 'view/layout/Left-Section'
import './index.scss'

import QRCodeGenerator from './qrcodes' // Assuming this import is correct
import { getQrListApi } from 'helper/apiHelper'
import { handleSave } from './helper'

const MyQRCodes = () => {
  useEffect(() => {
    document.title = 'My QR Codes'
    fetchImageData() // Uncommented for simplicity
  }, [])

  const [qrData, setQrData] = useState([])

  const fetchImageData = async () => {
    try {
      const updatedData = await getQrListApi()
      setQrData(updatedData)
    } catch (error) {
      // Handle error appropriately
      console.error('Error fetching QR data:', error)
    }
  }

  return (
    <div className='container-div'>
      <Layout />
      <div className='container-div2'>
        <LeftSection name='qr' />

        <div className='right-container'>
          <QRCodeGenerator fetchImageData={fetchImageData} qrData={qrData} />

          <div className='container-image'>
            {qrData?.length > 0 ? (
              <div>
                <div className='create-new-color'>My QR Codes</div>
                <div className='image-container-list'>
                  {qrData.map((container, index) => (
                    <div key={index}>
                      <div className='qr-containers'>
                        <div className='qr-wrapper'>
                          {container.qr_code_extension === 'svg' ? (
                            <div dangerouslySetInnerHTML={{ __html: container.qr_code_url }} />
                          ) : (
                            <img src={container.qr_code_url} alt='' className='images' />
                          )}
                        </div>{' '}
                        {container.qr_code_extension === 'svg' ? (
                          <button className='save-generate' onClick={() => handleSave('svg', container.qr_code_url)}>
                            Download SVG
                          </button>
                        ) : (
                          <button className='save-generate' onClick={() => handleSave('png', container.qr_code_url)}>
                            Download PNG
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyQRCodes
