import React, { useEffect, useState } from 'react'
import './index.scss'
import { getHighestCurrentVersion, handleLaunchEditor } from 'common/helpers'
import Archives from './archives'
import { useProjectVersionList } from 'components/customhook/useProjectVersionList'
import { useProjectList } from 'components/customhook'

const Project = ({ project }) => {
  const { getProjectVersionList, projectVersionsList, createNewProjectVersion, disableProjectVersion, fetchProjectVersionThumbnail } =
    useProjectVersionList()

  const { disableProject } = useProjectList()

  const [projectThumbnailURL, setProjectThumbnailURL] = useState(null)
  const highestVersion = getHighestCurrentVersion(projectVersionsList)

  const fetchThumbnail = async () => {
    const url = await fetchProjectVersionThumbnail(project.project_id, highestVersion)
    setProjectThumbnailURL(url)
  }

  useEffect(() => {
    getProjectVersionList(project.project_id)
    if (highestVersion) {
      fetchThumbnail()
    }
  }, [highestVersion]) // Only depend on isVisible

  const projectTitle = highestVersion ? `${project.project_name} - v${highestVersion}` : project.project_name

  const handleCreateVersion = async () => {
    const response = await createNewProjectVersion(project.project_id, highestVersion)

    setProjectThumbnailURL(null)
    const url = await fetchProjectVersionThumbnail(project.project_id, response.versionId)
    setTimeout(() => {
      setProjectThumbnailURL(url)
    }, 1000)
  }

  const handleOpenProject = () => {
    handleLaunchEditor(project.project_id, highestVersion)
  }

  const handleDeleteProjectVersion = () => {
    // alert('Feature coming soon')
    if (window.confirm(`Are you sure you want to delete project - ${project.project_id}?`)) {
      disableProject(project.project_id)
    } else {
      // Handle user canceling the confirmation
      console.log('Project deletion canceled.') // Example for logging
    }
  }

  return (
    <div key={project.project_id} className='myproj-cards'>
      <div className='proj-img-card'>{projectThumbnailURL && <img src={projectThumbnailURL} alt='' />}</div>
      <div className='proj-card-info'>
        <h2 className='project-title'>{projectTitle}</h2>
        <p className='updates'>(last updated: {project.last_updated})</p>
        <div className='proj-access'>
          <button onClick={handleOpenProject} className='launch-btn'>
            Edit Project
          </button>
          <button onClick={handleCreateVersion} className='dwn-btn'>
            Create a new version
          </button>
          <button onClick={handleDeleteProjectVersion} className='dup-proj-btn'>
            Delete project
          </button>
        </div>
        <Archives
          project={project}
          createNewProjectVersion={createNewProjectVersion}
          projectVersionsList={projectVersionsList}
          deleteProjectVersion={disableProjectVersion}
          setProjectThumbnailURL={setProjectThumbnailURL}
          fetchProjectVersionThumbnail={fetchProjectVersionThumbnail}
        />
      </div>
    </div>
  )
}

export default Project
