import React, { useEffect, useState, useRef } from 'react'
import './index.scss'
import uploadIcon from '../../assets/images/my-isi/isi-upload-icon.png'
import LeftSection from 'view/layout/Left-Section'
import Layout from 'view/layout/Header'
import { pdfjs } from 'react-pdf'
import toast from 'react-hot-toast'
import { checkFileLimit, checkFileSize, getUserIdFromLocalStorage } from 'config/apiConfig'
import { getMyIsiList, handleIsiUpload } from 'helper/apiHelper'
import MySavedIsi from './MySavedIsi'

const MyImportantSafetyInformation = () => {
  const [uploadedFile, setUploadedFile] = useState(null)
  const [filename, setFileName] = useState('')
  const [isiPdf, setIsiPdf] = useState('')
  const [isLoading, setIsLoading] = useState(false) // Loading state
  const user_id = getUserIdFromLocalStorage()
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [myIsiData, setMyIsiData] = useState([])

  //doing file upload state as null
  const fileInputRef = useRef(null)

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the file input
    }
  }
  useEffect(() => {
    document.title = 'My Important Safety Information'
    fetchMyISIData()
  }, [])

  const fetchMyISIData = async () => {
    const updatedData = await getMyIsiList()
    setMyIsiData(updatedData)
  }

  const handleFileDrop = async (e) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (checkFileSize(file.size)) {
      if (checkFileLimit(myIsiData.length)) {
        // Check the file limit
        setUploadedFile(file)
        handlePdfInfo(file)
      } else {
        toast.error('You cannot upload more than 50 files into your library.')
      }
    } else {
      toast.error('Please upload a file with a size of 50MB or less.')
    }
  }
  const handleFileChange = async (e) => {
    const file = e.target.files[0]
    if (checkFileSize(file.size)) {
      if (checkFileLimit(myIsiData.length)) {
        // Check the file limit
        setUploadedFile(file)
        handlePdfInfo(file)
      } else {
        toast.error('You cannot upload more than 50 files into your library.')
      }
    } else {
      toast.error('Please upload a file with a size of 50MB or less.')
    }
  }

  const handlePdfInfo = async (pdffile) => {
    if (pdffile) {
      try {
        // Initialize PDF.js
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

        // Load and parse the PDF
        const pdf = await pdfjs.getDocument(URL.createObjectURL(pdffile)).promise
        const numPages = pdf.numPages
        let pdfText = ''

        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i)
          const textContent = await page.getTextContent()
          pdfText += textContent.items.map((item) => item.str).join(' ')
        }

        // Extract information from the parsed text
        const extractedInfo = extractInfoFromText(pdfText)
        if (
          !extractedInfo.indication &&
          !extractedInfo.important_safety_information &&
          !extractedInfo.warnings_precautions &&
          !extractedInfo.adverse_reactions &&
          !extractedInfo.please_see
        ) {
          toast.error('Please upload another file, this file does not match the format')
          return
        }
        // Update the parent state variable with extracted information
        setIsiPdf(extractedInfo)
        setIsSaveButtonDisabled(
          !extractedInfo.indication &&
            !extractedInfo.important_safety_information &&
            !extractedInfo.warnings_precautions &&
            !extractedInfo.adverse_reactions &&
            !extractedInfo.please_see
        )
      } catch (error) {
        toast.error('Error processing PDF:', error)
      }
    }
  }

  const extractInfoFromText = (text) => {
    //Validation
    const conditonOne = '([ ]{2}.*?[ ]{2}.*?)(|$)'
    const conditonTwo = '(.*?[ ]{2})(|$)'

    const indicationRegex = new RegExp(`Indication(${conditonOne}|${conditonTwo})`)
    const ImportantSafteyRegex = new RegExp(`Important Safety Information(${conditonOne}|${conditonTwo})`)
    const warningRegex = new RegExp(`Warning and Precautions(${conditonOne}|${conditonTwo})`)
    const adverseRegex = new RegExp(`Adverse Reactions(${conditonOne}|${conditonTwo})`)
    const pleaseRegex = new RegExp(/Please see([\s\S]*?)(?=\.\.\.|\s*$)/i)

    const importantMatch = text.match(ImportantSafteyRegex)
    const indicationMatch = text.match(indicationRegex)
    const warningMatch = text.match(warningRegex)
    const adverseMatch = text.match(adverseRegex)
    const pleaseMatch = text.match(pleaseRegex)

    const extractedInfo = {
      indication: indicationMatch ? indicationMatch[1] : '',
      important_safety_information: importantMatch ? importantMatch[1] : '',
      warnings_precautions: warningMatch ? warningMatch[1] : '',
      adverse_reactions: adverseMatch ? adverseMatch[1] : '',
      please_see: pleaseMatch ? pleaseMatch[1] : ''
    }

    return extractedInfo
  }

  const saveIsiData = () => {
    if (!uploadedFile) {
      toast.error('Please upload a PDF file before saving')
      return
    }
    if (!filename) {
      toast.error('ISI name is not allowed to be empty')
      return
    }
    // Check the file type using its extension
    const allowedExtensions = ['.pdf', '.doc', '.docx']
    const fileExtension = uploadedFile.name.slice(((uploadedFile.name.lastIndexOf('.') - 1) >>> 0) + 2)

    if (!allowedExtensions?.includes(`.${fileExtension}`)) {
      toast.error('Please upload a PDF or Word document')
      return
    }

    try {
      if (user_id) {
        setIsLoading(true)
        if (isSaveButtonDisabled) {
          return
        }
        handleIsiUpload(uploadedFile, isiPdf, filename, 'files')
          .then((response) => {
            console.log('API Response:', response.data)

            // Clearing input fields
            setUploadedFile(null) // Clear uploadedFile
            setFileName('') // Clear fileName
            // setCount((count) => count + 1)
            fetchMyISIData()
            setIsiPdf('')

            toast.success('ISI PDF saved successfully', { autoClose: 3000 })
            clearFileInput()
          })
          .catch((error) => {
            console.error('Error sending data to the API:', error)
            toast.error('Failed to save ISI PDF. Please try again.')
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    } catch (error) {
      console.error('Error processing PDF:', error)
      toast.error('An error occurred. Please try again.')
    }
  }
  return (
    <div className='container-div'>
      <Layout />
      <div className='container-div2'>
        <LeftSection name='isi' />

        <div className='right-container my-isi'>
          <div className='right-section-title'>My Important Safety Information (ISI)</div>

          <div className='isi-docs'>
            <div className='isi-doc-upload'>
              <form id='form-file-upload'>
                <label id='label-file-upload' htmlFor='input-file-upload'>
                  <div
                    className='isi-upload-dashed-border'
                    onDragOver={(e) => {
                      e.preventDefault()
                    }}
                    onDrop={handleFileDrop}
                  >
                    <p>Drag a File Here or</p>
                    <img src={uploadIcon} alt='' />
                    <input type='file' id='input-file-upload' ref={fileInputRef} onChange={handleFileChange} />
                    {uploadedFile && <div className='uploaded-file-name'>{uploadedFile.name}</div>}
                    <div className='upload-button'>Upload a File</div>
                  </div>
                </label>
              </form>
            </div>
            <div className='isi-add-new-doc'>
              <p className='create-new-color'>Add a New Document</p>
              <p className='isi-desc'>
                Start by adding a word document or PDF into our system. We’ll scan your document and identify key portions of your ISI. Once
                your file is uploaded and scanned, simply provide a name for your file and click “save”.
              </p>
              <div className='isi-inputs'>
                <input type='text' placeholder='Name Your ISI' value={filename} onChange={(e) => setFileName(e.target.value)} />
                <button type='button' onClick={saveIsiData} disabled={isLoading || isSaveButtonDisabled}>
                  {isLoading ? (
                    <div className='loader'></div> // Loader while loading
                  ) : (
                    'Save ISI' // Button text when not loading
                  )}
                </button>
              </div>
            </div>
          </div>
          <MySavedIsi myIsiData={myIsiData} setMyIsiData={setMyIsiData} />
        </div>
      </div>
    </div>
  )
}

export default MyImportantSafetyInformation
