import React, { useEffect, useState } from 'react'
import Banner from 'assets/images/landing-page/banner.png'
import card1 from 'assets/images/landing-page/autonome-platform.png'
import card2 from 'assets/images/landing-page/roadmap.png'
import card3 from 'assets/images/landing-page/kols.png'
import icon1 from 'assets/images/landing-page/money.png'
import icon2 from 'assets/images/landing-page/invoice.png'
import icon3 from 'assets/images/landing-page/completed.png'
import icon4 from 'assets/images/landing-page/question.png'
import rocketLogo from 'assets/images/landing-page/logo.svg'
import { Link } from 'react-router-dom'
// import { useNavigate } from "react-router-dom";
import axios from 'axios'
import toast from 'react-hot-toast'

import './index.scss'
import Layout from 'view/layout/Header'

const LandingPage = () => {
  // let navigate = useNavigate();

  useEffect(() => {
    document.title = '40knots Autonome'
  }, [])

  //Form Process
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company_name: '',
    telephone: '',
    opt_newsletter: false
  })

  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target

    // Validate checkbox separately
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked ? '1' : '0'
      })
    } else {
      setFormData({
        ...formData,
        [name]: value
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validationErrors = validateForm(formData)

    if (Object.keys(validationErrors).length === 0) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL + '/api/leads'
        const headers = {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY
        }

        await axios.post(apiUrl, formData, { headers: headers })

        setFormData({
          name: '',
          email: '',
          company_name: '',
          telephone: '',
          opt_newsletter: false
        })
        setErrors({})
        toast.success('Form submitted successfully!')
      } catch (error) {
        console.error('Error:', error)
        toast.error('Error submitting form')
      }
    } else {
      setErrors(validationErrors)
    }
  }

  const validateForm = (data) => {
    const errors = {}

    if (!data.name.trim()) {
      errors.name = 'Name is required'
    }

    if (!data.email.trim()) {
      errors.email = 'Email is required'
    } else if (!isValidEmail(data.email)) {
      errors.email = 'Invalid email address'
    }

    if (!data.company_name.trim()) {
      errors.company_name = 'Company name is required'
    }

    if (!data.telephone.trim()) {
      errors.telephone = 'Telephone is required'
    } else if (!isValidTelephone(data.telephone)) {
      errors.telephone = 'Invalid telephone number'
    }

    if (!data.opt_newsletter) {
      errors.opt_newsletter = 'This is required'
    }

    return errors
  }

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const isValidTelephone = (telephone) => {
    const telephoneRegex = /^\d{10}$/ // Assuming a 10-digit telephone number
    return telephoneRegex.test(telephone)
  }

  return (
    <div className='container-landing'>
      <Layout />

      <div className='banner-landing'>
        <section className='banner-section'>
          <div className='container-landing'>
            <div className='container-left-landing'>
              <div className='banner-content'>
                <h1>With 40 Knots, that’s no problem</h1>
                <p>Preparing to launch with no time, no team, and small budget?</p>
              </div>
              <div className='banner-btn'>
                <a href='#contactus' className='primary-btn'>
                  Contact Us
                </a>
                <Link to='/login' className='primary-btn'>
                  Login
                </Link>
              </div>
            </div>
            <div className='container-right-landingpage'>
              <img src={Banner} alt='Banner' className='w-100' />
            </div>
          </div>
        </section>
        <section className='budget-section'>
          <div className='container-landing'>
            <h2 className='heading-text'>40 Knots puts you in control of your launch, and your budget</h2>
            <div className='budget-cards'>
              <div className='cards-info'>
                <div className='cards-inner'>
                  <img src={card1} alt='' />
                  <p className='cards-heading'>AUTONOME PLATFORM</p>
                  <p className='cards-content'>
                    The AutonoME platform gives you the ability to create customizable marketing solutions with templates designed
                    specifically for the pharmaceutical and medical device industries. You can create tools and presentations for your brand
                    at a fraction of the cost of a typical agency.
                  </p>
                  <Link to='#' className='primary-btn'>
                    Learn More
                  </Link>
                </div>
              </div>
              <div className='cards-info'>
                <div className='cards-inner'>
                  <img src={card2} alt='' />
                  <p className='cards-heading'>ROAD MAP</p>
                  <p className='cards-content'>
                    The 40 Knots Roadmap helps you define strategy, structure and commitments to grow and scale your commercialization
                    readiness- all without an army of consultants
                  </p>
                  <Link to='#' className='primary-btn'>
                    Learn More
                  </Link>
                </div>
              </div>
              <div className='cards-info'>
                <div className='cards-inner'>
                  <img src={card3} alt='' />
                  <p className='cards-heading'>KOLs</p>
                  <p className='cards-content'>Sit amet, consectetur adipiscing elit. In eget volutpat dolor, ac ultrices lorem.</p>
                  <Link to='#' className='primary-btn'>
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='branding-section'>
          <div className='container-landing'>
            <div className='branding-inner'>
              <h2 className='heading-text'>40 Knots puts you in control of your launch, and your budget</h2>
              <p className='text-heading center-text'>Branding Kits & Templates</p>
              <p className='text-para center-text'>
                For each launch, it is critical to communicate the value of not only your product, but your programs and services in order
                to get the HCP to a yes. 40 Knots offers you the ability to easily create personalized industry accepted collateral
                featuring your brand’s logos, colors and product specific information quickly and inexpensively.
              </p>
              <p className='strong-heading1'>Examples of customizable collateral include:</p>
              <div className='example-data'>
                <div className='icons'>
                  <img src={icon1} alt='' />
                  <p className='ex-text'>
                    Copay <br /> Programs
                  </p>
                </div>
                <div className='icons'>
                  <img src={icon2} alt='' />
                  <p className='ex-text'>
                    Billing and <br />
                    Coding
                  </p>
                </div>
                <div className='icons'>
                  <img src={icon3} alt='' />
                  <p className='ex-text'>
                    Product Ordering <br />
                    Information
                  </p>
                </div>
                <div className='icons'>
                  <img src={icon4} alt='' />
                  <p className='ex-text'>Product FAQs</p>
                </div>
              </div>
              <p className='text-heading left'>Training Materials</p>
              <p className='text-para'>
                40 Knots can also help instill the confidence your team needs to successfully engage with HCPs at launch. Basic training
                decks for your team that cover the nuances of the business, market access principles and implications, as well as customer
                engagement and objection handling are just a few examples of how we can help train your team. To fully understand the
                healthcare landscape, it is important to understand the players involved, their roles, and the relative levels of influence
                that each may have on patient access to treatment. Our training modules are an easy and inexpensive way to support the
                knowledge foundation of your team and ultimately enhance communications of your product and company value.
              </p>

              <p className='strong-heading2'>Examples of Training Modules:</p>
              <ul>
                <li>Market Access and Reimbursement</li>
                <li>Strategic Account Management</li>
                <li>Value Communications</li>
              </ul>

              <p className='text-heading left'>Roadmap Development</p>
              <p className='text-para'>
                Launching your product is complex and costly- there are fewer resources, competing priorities within the organization, and
                hiring consultants to manage your launch timeline and activities is time consuming and expensive. Without the right roadmap
                guiding your product launch, duplication of efforts or missed communications with the right stakeholders can cause delays
                and derail your launch momentum. Let us help take you from a start-up mindset to becoming a high-performing organization.The
                40 Knots strategic roadmap commercialization plan will outline activities, by function, required for a successful launch.
              </p>
              <p className='strong-heading2'>A sample of those functions included:</p>
              <ul>
                <li>Market Access</li>
                <li>Marketing</li>
                <li>Business Analysis & Insights</li>
                <li>Sales & Sales Operations</li>
                <li>Corporate Communications</li>
                <li>Corporate Functions, such as Supply Chain, Regulatory & Legal</li>
                <li>Sales Training & Development</li>
              </ul>
            </div>
          </div>
        </section>

        <section className='section-four'>
          <div>
            <div>
              <h1>WE&apos;VE GOT YOUR BACK</h1>
              <img src={rocketLogo} alt='Logo' className='rocket-logo' />
              <p>
                We have designed our approach to be as intuitive and user-friendly as possible, but we know questions might still come up.
                We are happy to provide guidance and make sure you are leveraging everything the 40 Knots has to offer.
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className='section-five'>
            <div className='banner-five'>
              <div className='inner-banner'>
                <h1>Diversity, Equity, and Inclusion</h1>
              </div>

              <p className='inner-info'>
                Developing an approach to commercialization plans and tactics which focuses on diversity, equity, and inclusion can increase
                sales revenue, the customer base, and ultimately profits. 40 Knots is committed to ensuring our products and services are
                representative of the demographics of the healthcare consumer base. 40 Knots strives to partner only with manufacturers that
                make this same commitment.
              </p>
            </div>
            <div className='footer-section' id='contactus'>
              <div className='left-div'>
                <h2 className='section-heading'>Get in touch</h2>

                <p className='text-left'>
                  Are you ready to shake up the traditional approach of commercializing your product?The 40 Knots suite of solutions helps
                  you realize your product’s maximum potential while supporting your company goals, all while minimizing your time and
                  effort.
                  <p>
                    If you want to dive deeper into the exciting possibilities of what 40 Knots can do for you, we invite you to connect
                    with us to learn more about how our solutions can transform your business processes. Whether you’re a curious individual
                    or a visionary leader, we are here to provide you with a clear understanding of how our solutions can meet your specific
                    needs.
                  </p>{' '}
                  Reach out today to schedule a personalized demo, request detailed information, or simply engage in a discussion about what
                  makes 40 Knots a game-changer.
                </p>
              </div>
              <div className='right-div'>
                <form onSubmit={handleSubmit}>
                  <p className='details'>Enter Your Details</p>
                  <div className='form-fields'>
                    <div className='input-field'>
                      <input type='text' name='name' value={formData.name} placeholder='Name' onChange={handleInputChange} />
                      {errors.name && <span className='error-color'>{errors.name}</span>}
                    </div>
                    <div className='input-field'>
                      <input type='email' name='email' value={formData.email} placeholder='Business Email' onChange={handleInputChange} />
                      {errors.email && <span className='error-color'>{errors.email}</span>}
                    </div>
                    <div className='input-field'>
                      <input
                        type='text'
                        name='company_name'
                        value={formData.company_name}
                        placeholder='Company Name'
                        onChange={handleInputChange}
                      />
                      {errors.company_name && <span className='error-color'>{errors.company_name}</span>}
                    </div>
                    <div className='input-field'>
                      <input
                        type='number'
                        name='telephone'
                        value={formData.telephone}
                        placeholder='Telephone'
                        onChange={handleInputChange}
                      />
                      {errors.telephone && <span className='error-color'>{errors.telephone}</span>}
                    </div>
                    <div className='input-field input-checkbox'>
                      <div className='checkbox-div'>
                        <input type='checkbox' name='opt_newsletter' checked={formData.opt_newsletter} onChange={handleInputChange} />
                        <label htmlFor='checkbox'>I would like to receive news and updates from 40 Knots.</label>
                      </div>
                      {errors.opt_newsletter && <span className='error-color'>{errors.opt_newsletter}</span>}
                    </div>
                    <button type='submit' className='submit-button'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className='section-footer'>
        <div className='final-container'>
          <span className='terms'>
            <p>40 Knots™ 888 Prospect Street Suite 200 La Jolla, California 92037 USA</p>
            <p className='color-change'>
              <b>Terms of Use | Privacy Policy | Cookie Policy</b>
            </p>
            <p>© 2023 Corsaire Corporation™. All rights reserved.</p>
          </span>
        </div>
      </section>
    </div>
  )
}

export default LandingPage
