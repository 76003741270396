import React from 'react'
import PropTypes from 'prop-types'

const PreviewFontComponent = ({ headStyle, subheadStyle, bodyStyle, fontId }) => {
  return (
    <div className='additional-div-font'>
      <h2 className={`apply-font-headline${fontId}`} style={headStyle}>
        This is what your headline will look like
      </h2>
      <p className={`apply-font-subheadline${fontId}`} style={subheadStyle}>
        This is sample sub headline copy
      </p>
      <p className={`apply-font-bodyline${fontId}`} style={bodyStyle}>
        This is sample body copy text. To illustrate how a block of copy looks compared to the headline and sub headline, it’s important to
        have a substantial amount of text here.
      </p>
    </div>
  )
}
export default PreviewFontComponent

PreviewFontComponent.propTypes = {
  headStyle: PropTypes.object,
  subheadStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
  fontId: PropTypes.number
}
