import React, { useEffect, useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import Layout from 'view/layout/Header'

const SendEmail = () => {
  let navigate = useNavigate()

  // Form Process
  const [formData, setFormData] = useState({
    username: '',
    url: `${window.location.origin}/forgot-password/`
  })

  // Loading state
  const [loading, setLoading] = useState(false)

  const checkLocalUser = () => {
    const check = JSON.parse(localStorage.getItem('username'))
    if (check) {
      navigate('/dashboard')
    }
  }

  useEffect(() => {
    checkLocalUser()
    document.title = 'Send Verification Link'
    const params = new URLSearchParams(window.location.search)
    const username = params.get('username')

    setFormData((prevFormData) => ({
      ...prevFormData,
      username: username || ''
    }))
  }, [])

  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Prevent multiple submissions
    if (loading) {
      return
    }

    try {
      setLoading(true) // Start loader

      const apiUrl = process.env.REACT_APP_API_URL + '/api/forgot-password/usercheck'
      const headers = {
        'Content-Type': 'application/json',
        'api-key': process.env.REACT_APP_API_KEY
      }

      const response = await axios.get(apiUrl, {
        params: formData,
        headers: headers
      })

      console.log(response)

      setFormData({
        username: '',
        url: `${window.location.origin}/forgot-password/`
      })

      setErrors({})
      toast.success('Password reset link sent to registered email')
    } catch (error) {
      console.error('Error:', error)
      toast.error('Error')
    } finally {
      setLoading(false) // Stop loader
    }
  }

  return (
    <div className='container'>
      <Layout />
      <div className='container1'>
        <div className='container-left'>
          <h1 className='welcome'>Welcome</h1>
          <div className='first-line'>Thank you for registering with the 40 Knots AutonoME platform.</div>
        </div>
        <div className='container-right'>
          <form className='user-form' onSubmit={handleSubmit}>
            <p className='heading2'>Enter your registered email or username</p>

            <div className='input-box'>
              <input type='text' name='username' placeholder='Username or Email' value={formData.username} onChange={handleInputChange} />
              {errors.username && <span className='error-color'>{errors.username}</span>}
            </div>

            <button className='register-button' disabled={loading}>
              {loading ? 'Sending...' : 'Send Verification Link'}
            </button>
          </form>
        </div>
        <div>
          <div className='container3'>
            <span className='terms'>
              <p>40 Knots™ 888 Prospect Street Suite 200 La Jolla, California 92037 USA</p>
              <p>
                <b>Terms of Use | Privacy Policy | Cookie Policy</b>
              </p>
              <p>© 2023 Corsaire Corporation™. All rights reserved.</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendEmail
