import axios from 'axios'
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

async function fetchAndConvertPdf(s3Link) {
  try {
    const response = await axios.get(s3Link, { responseType: 'arraybuffer' })
    const pdfData = new Uint8Array(response.data)
    const loadingTask = pdfjs.getDocument({ data: pdfData })

    const pdf = await loadingTask.promise
    const page = await pdf.getPage(1)
    const scale = 1.5
    const viewport = page.getViewport({ scale })
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width

    await page.render({ canvasContext: context, viewport }).promise
    const imgSrc = canvas.toDataURL('image/png')
    return imgSrc
  } catch (error) {
    console.error('Error fetching and converting PDF:', error)
  }
}

export { fetchAndConvertPdf }
