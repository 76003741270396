import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const PrivateRoute = ({ component: Component }) => {
  let navigate = useNavigate()

  useEffect(() => {
    let checkIt
    const checkLocalUser = () => {
      const check = JSON.parse(localStorage.getItem('token'))
      if (!check) {
        clearInterval(checkIt)
        navigate('/')
      }
    }
    checkIt = setInterval(() => {
      checkLocalUser()
    }, 1000)

    return () => {
      clearInterval(checkIt)
    }
  }, [])
  const isAuthenticated = JSON.parse(localStorage.getItem('token'))
  return isAuthenticated ? <Component /> : <Navigate to='/login' />
}

export default PrivateRoute
