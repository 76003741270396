import React, { useEffect, useState } from 'react'
import Layout from 'view/layout/Header'
import './index.scss'
import { getCategories, headers, getAppTypes, filterTemplate } from 'config/apiConfig'
import dropdown from 'assets/images/my-reference/dropdown.svg'
import axios from 'axios'
import { fetchAllTemplates, getAllTemplatesByType } from 'helper/apiHelper'
import Masonry from 'react-responsive-masonry'
import close from 'assets/images/Group 235.png'
import { generatePresignedUrl } from 'helper/s3Helper'
import { handleLaunchEditorBaseTemplate } from 'common/helpers'

const SelectTemplate = () => {
  useEffect(() => {
    document.title = 'Explore Templates'
  }, [])

  const [selectedOption, setSelectedOption] = useState('All')
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [categories, setCategories] = useState([])
  const [appTypes, setAppTypes] = useState([])
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const [selectedTypeIds, setSelectedTypeIds] = useState([])
  const [templates, setTemplates] = useState([])
  const [templateType, setTemplateType] = useState('')

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(getCategories(), { headers })
        setCategories(response.data)
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    }
    const fetchAppTypes = async () => {
      try {
        const response = await axios.get(getAppTypes(), { headers })
        setAppTypes(response.data)
      } catch (error) {
        console.error('Error fetching app types:', error)
      }
    }
    fetchCategories()
    fetchAppTypes()
  }, [])

  useEffect(() => {
    if (selectedOption === 'All') {
      fetchallTemplates()
    } else {
      fetchTemplatesByType(selectedOption)
      setTemplateType(selectedOption)
    }
  }, [selectedOption])

  const fetchallTemplates = async () => {
    try {
      const updatedData = await fetchAllTemplates()
      setTemplates(updatedData)
    } catch (error) {
      console.error('Error fetching templates:', error)
    }
  }

  const fetchTemplatesByType = async (type) => {
    try {
      const response = await getAllTemplatesByType(type)
      setTemplates(response)
      setTemplateType(type)
    } catch (error) {
      console.error(`Error fetching ${type} templates:`, error)
    }
  }
  //clearing filters
  const clearAllFilters = () => {
    setSelectedCategoryIds([])
    setSelectedTypeIds([])
    if (selectedOption === 'All') {
      fetchallTemplates()
    } else {
      fetchTemplatesByType(selectedOption)
    }
  }

  const handleOptionClick = (option) => {
    if (option === 'All') {
      setSelectedCategoryIds([])
      setSelectedTypeIds([])
      fetchAllTemplates()
    }
    setSelectedOption(option)
    clearAllFilters()
    setTemplateType(option)
    closeFilter()
    fetchTemplatesByType(option)
  }
  //filter dropdown
  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const closeFilter = () => {
    setIsFilterOpen(false)
  }

  const handleCheckboxChange = (categoryId) => {
    const categoryIndex = selectedCategoryIds.indexOf(categoryId)
    let updatedCategoryIds = [...selectedCategoryIds]
    if (categoryIndex === -1) {
      updatedCategoryIds.push(categoryId)
    } else {
      updatedCategoryIds.splice(categoryIndex, 1)
    }
    setSelectedCategoryIds(updatedCategoryIds)
    fetchTemplatesWithFilters(selectedOption, updatedCategoryIds, selectedTypeIds)
  }

  const handleCheckboxChangeType = (typeId) => {
    const typeIndex = selectedTypeIds.indexOf(typeId)
    let updatedTypeIds = [...selectedTypeIds]
    if (typeIndex === -1) {
      updatedTypeIds.push(typeId)
    } else {
      updatedTypeIds.splice(typeIndex, 1)
    }
    setSelectedTypeIds(updatedTypeIds)
    fetchTemplatesWithFilters(selectedOption, selectedCategoryIds, updatedTypeIds)
  }

  const fetchTemplatesWithFilters = async (selectedOption, selectedCategories, selectedTypes) => {
    try {
      const response = await axios.get(filterTemplate(selectedOption, selectedCategories.join(','), selectedTypes.join(',')), { headers })
      if (response.data && response.data.length > 0) {
        const updatedData = await Promise.all(
          response.data.map(async (tempObj) => {
            const s3Key = `templates/${tempObj.template_slug}/${tempObj.template_thumbnail_url}`
            const presignedUrl = await generatePresignedUrl(s3Key)

            return { ...tempObj, template_thumbnail_url: presignedUrl }
          })
        )
        response.data = updatedData
        setTemplates(response.data)
      } else {
        setTemplates([])
      }
    } catch (error) {
      console.error('Error fetching filtered templates:', error)
    }
  }

  const getCategoryName = (categoryId) => {
    const selectedCategory = categories.find((category) => category.category_id === categoryId)
    return selectedCategory ? selectedCategory.category_name : ''
  }

  const getTypeName = (typeId) => {
    const selectedType = appTypes.find((type) => type.type_id === typeId)
    return selectedType ? selectedType.type_name : ''
  }

  const formatTemplateName = (templateName) => {
    return templateName.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())
  }

  const handleRemoveCategory = (categoryId) => {
    const updatedCategoryIds = selectedCategoryIds.filter((id) => id !== categoryId)
    setSelectedCategoryIds(updatedCategoryIds)
    fetchTemplatesWithFilters(selectedOption, updatedCategoryIds, selectedTypeIds)
    if (updatedCategoryIds.length === 0 && selectedTypeIds.length === 0) {
      fetchallTemplates()
    }
  }

  const handleRemoveType = (typeId) => {
    const updatedTypeIds = selectedTypeIds.filter((id) => id !== typeId)
    setSelectedTypeIds(updatedTypeIds)
    fetchTemplatesWithFilters(selectedOption, selectedCategoryIds, updatedTypeIds)
    if (selectedCategoryIds.length === 0 && updatedTypeIds.length === 0) {
      fetchallTemplates()
    }
  }

  return (
    <div className='container-div'>
      <Layout />
      <div className='container-div2'>
        <div className='right-container'>
          <div className='template-wrapper'>
            <div className='right-section-title'>Select Template</div>
            <div className='options'>
              <div className={`box ${selectedOption === 'All' ? 'selected' : ''}`} onClick={() => handleOptionClick('All')}>
                All
              </div>
              <div className={`box ${selectedOption === 'Brochures' ? 'selected' : ''}`} onClick={() => handleOptionClick('Brochures')}>
                Brochures
              </div>
              <div className={`box ${selectedOption === 'Flash cards' ? 'selected' : ''}`} onClick={() => handleOptionClick('Flash cards')}>
                Flash Cards
              </div>
              <div
                className={`box ${selectedOption === 'Core Visual Aids' ? 'selected' : ''}`}
                onClick={() => handleOptionClick('Core Visual Aids')}
              >
                Core Visual Aids
              </div>
              <div className={`box ${selectedOption === 'PDF' ? 'selected' : ''}`} onClick={() => handleOptionClick('PDF')}>
                PDF
              </div>
              <div
                className={`box ${selectedOption === 'Presentation' ? 'selected' : ''}`}
                onClick={() => handleOptionClick('Presentation')}
              >
                Presentations
              </div>
              <div
                className={`box ${selectedOption === 'Presentations' ? 'selected' : ''}`}
                onClick={() => handleOptionClick('Presentations')}
              >
                Presentations
              </div>
            </div>
            <div className='wrapper'>
              {selectedCategoryIds.map((categoryId) => (
                <div key={categoryId} className='temp-btn'>
                  <div className='cat-type-name background'>
                    <img src={close} className='close-image' onClick={() => handleRemoveCategory(categoryId)} />
                    <p>{getCategoryName(categoryId)}</p>
                  </div>
                </div>
              ))}
              {selectedTypeIds.map((typeId) => (
                <div key={typeId} className='temp-btn'>
                  <div className='cat-type-name background'>
                    <img src={close} className='close-image' onClick={() => handleRemoveType(typeId)} />
                    <p>{getTypeName(typeId)}</p>
                  </div>
                </div>
              ))}
              {(selectedCategoryIds.length > 0 || selectedTypeIds.length > 0) && (
                <div className='filter-clear' onClick={clearAllFilters}>
                  Clear all
                </div>
              )}
            </div>

            <div className='filter-template-wrapper'>
              {(categories?.length > 0 || appTypes?.length > 0) && (
                <div className='filter-options'>
                  <div className='filter' onClick={() => toggleFilter()}>
                    Filter
                    <img src={dropdown} alt='' />
                  </div>
                  {isFilterOpen && (
                    <div className='filter-list'>
                      {categories?.length > 0 && (
                        <div className='filter-category'>
                          <div className='filter-category-heading'>Category</div>
                          {categories?.map((category) => (
                            <div key={category.category_id} className='filter-category-item'>
                              <div className='checkbox-container'>
                                <input
                                  type='checkbox'
                                  id={`category-${category.category_id}`}
                                  checked={selectedCategoryIds.includes(category.category_id)}
                                  onChange={() => handleCheckboxChange(category.category_id)}
                                />
                                <label htmlFor={`category-${category.category_id}`}>{category.category_name}</label>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {appTypes?.length > 0 && (
                        <div className='filter-category'>
                          <div className='filter-category-heading'>Type</div>
                          {appTypes?.map((type) => (
                            <div key={type.type_id} className='filter-category-item'>
                              <div className='checkbox-container'>
                                <input
                                  type='checkbox'
                                  id={`type-${type.type_id}`}
                                  checked={selectedTypeIds.includes(type.type_id)}
                                  onChange={() => handleCheckboxChangeType(type.type_id)}
                                />
                                <label htmlFor={`type-${type.type_id}`}>{type.type_name}</label>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div className='template-container'>
                {templates?.length > 0 && (
                  <div className='template-list'>
                    {selectedOption !== 'All' && (
                      <div className='selected-template-type-heading'>
                        <p>{formatTemplateName(templateType)}</p>
                      </div>
                    )}
                    <Masonry columnsCount={5} gutter='20px'>
                      {templates
                        .filter((template) => template.template_id !== 0)
                        .map((template) => (
                          <div
                            onClick={() => {
                              handleLaunchEditorBaseTemplate(template.template_id)
                            }}
                            key={template.template_id}
                            className='template-item'
                          >
                            <img src={template.template_thumbnail_url} alt='' className='template-image' />
                            <div className='template-info'></div>
                          </div>
                        ))}
                    </Masonry>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SelectTemplate
