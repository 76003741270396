import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import knoteslogo from '../../../assets/40knots.svg'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { deleteCookie, handleLaunchEditor, isEditor } from 'common/helpers'
import './index.scss'

const Layout = ({ mousehover, mouseout, projectList }) => {
  const [navcolor, setNavcolor] = useState(false)
  const [activeClass, setActiveClass] = useState(false)
  const menuItemClass = 'menu-item'
  let location = useLocation()
  let navigate = useNavigate()
  const isEditorRole = isEditor()

  const isDashboardPath = location.pathname === '/dashboard'

  const handleLogout = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL + '/api/logout'
      const headers = {
        'Content-Type': 'application/json',
        'api-key': process.env.REACT_APP_API_KEY
      }
      await axios.post(apiUrl, {}, { headers: headers })
      localStorage.clear()

      const isRememberMeEnabled = Cookies.get('rememberMe')
      if (!isRememberMeEnabled) {
        deleteCookie('username')
        Cookies.remove('username')
      }
      deleteCookie('user_id')
      deleteCookie('token')
      toast.success('Logout successful')
      navigate('/')
    } catch (error) {
      console.error('Error:', error)
      toast.error('logout failed')
    }
  }

  const handleNavbar = () => {
    const pathnames = {
      LANDING: '/',
      LOGIN: '/login',
      TEMP: '/tempuser',
      REGISTER: '/register',
      PASSWORD_RESET: '/password-reset',
      FORGOT_PASSWORD: '/send-forgot-password-email'
      // Template_Upload: '/template-upload'
    }

    //validation for pathnames
    const ValidPathnames = Object.values(pathnames)
    if (ValidPathnames.includes(location.pathname)) {
      setNavcolor(true)
    } else {
      setNavcolor(false)
    }
  }

  const handleActive = () => {
    const arr = [
      '/mycolor-palettes',
      '/myfonts',
      '/mylogos',
      '/myimages',
      '/mycharts',
      '/myqrcodes',
      '/myreferences',
      '/myimportant-saftey-information',
      '/my-prescribing-information'
    ]
    setActiveClass(arr.includes(location.pathname))
  }

  useEffect(() => {
    handleNavbar()
    handleActive()
  }, [])

  const handleMenuItemClick = () => {
    if (location.pathname === '/dashboard' && projectList?.length > 0) {
      navigate('/myprojects')
    }
  }

  return (
    <div className='header-container'>
      <div className='layout-container'>
        {navcolor ? (
          <div className='navbar'>
            <div className='logo'>
              <Link to='/'>
                <img src={knoteslogo} alt='Logo' />
              </Link>
            </div>
          </div>
        ) : (
          <div>
            <div className='navBar'>
              <div className='navbar-logo'>
                <Link to='/dashboard'>
                  <img src={knoteslogo} alt='Logo' />
                </Link>
              </div>
              {/* <p className='help'>Help</p> */}

              <div className='right-button'>
                <button className='buttons' onClick={handleLogout}>
                  Log Out
                </button>
                {!isEditorRole && (
                  <button onClick={handleLaunchEditor} className='buttons'>
                    Create new project
                  </button>
                )}
              </div>
            </div>

            <nav className='menu'>
              <ul className='menu-list'>
                {isDashboardPath ? (
                  <li className='menu-item' onMouseEnter={mousehover} onMouseOut={mouseout} onClick={handleMenuItemClick}>
                    My Projects
                  </li>
                ) : (
                  <li className={`${menuItemClass} ${location.pathname === '/myprojects' ? 'active' : ''}`}>
                    <Link to='/myprojects'>My Projects</Link>
                  </li>
                )}
                <li className={`${menuItemClass} ${activeClass ? 'active' : ''}`}>
                  <Link to='/mycolor-palettes'>My Brand Library</Link>
                </li>
                <li className={`${menuItemClass} ${location.pathname === '/templates' ? 'active' : ''}`}>
                  <Link to='/templates'>Explore Templates</Link>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  )
}
Layout.propTypes = {
  mousehover: PropTypes.func,
  mouseout: PropTypes.func,
  projectList: PropTypes.array
}
export default Layout
