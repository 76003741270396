import React, { useEffect, useState } from 'react'

import Layout from 'view/layout/Header'
import LeftSection from 'view/layout/Left-Section'
import replaceIcon from 'assets/images/Replace.png'
import deleteIcon from 'assets/images/Delete.png'
import './index.scss'
import toast from 'react-hot-toast'
import { deleteImage, getImageListApi, handleImageUpload } from 'helper/apiHelper'
import AddSection from 'components/add-section'
import { checkImageLimit, checkImageSize } from 'config/apiConfig'

const MyImages = () => {
  useEffect(() => {
    document.title = 'My Images'
    fetchImageData()
  }, [])
  const fetchImageData = async () => {
    const updatedData = await getImageListApi('images')
    setImageData(updatedData)
  }
  const [activeIndex, setActiveIndex] = useState(null)
  const [imageData, setImageData] = useState()

  // Function to handle the image selection
  const handleImageSelect = async (e, image_id) => {
    const file = e.target.files[0]
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      if (checkImageSize(file.size)) {
        if (checkImageLimit(imageData.length)) {
          await handleImageUpload(file, 'images', image_id).then(() => {
            fetchImageData()
            toast.success('Saved Successfuly')
          })
        } else {
          toast.error('You cannot upload more than 50 images into you library')
        }
      } else {
        toast.error('Please upload an image file with a size of 50MB or less.')
      }
    } else {
      console.error('Invalid file type. Please select a JPG or PNG image.')
    }
  }
  return (
    <div className='container-div'>
      <Layout />
      <div className='container-div2'>
        <LeftSection name='images' />
        <div className='right-container'>
          <div className='container-image'>
            <div className='right-section-title'>My Images</div>
            <div>
              <div className='image-container-list'>
                {imageData?.map((container, index) => (
                  <div
                    key={index}
                    className={`image-containers ${activeIndex === index ? 'active' : ''}`}
                    onClick={() => setActiveIndex(index)} // Set the active index on click
                  >
                    <div className='image-wrapper'>
                      <img src={container.image_url} alt='' className='images' />
                    </div>
                    <div className='image_name'>{container.image_name}</div>
                    {activeIndex === index && (
                      <div className='small-container'>
                        {activeIndex === index && (
                          <div className='small-container'>
                            <label>
                              <img src={replaceIcon} alt='add color' />
                              <input type='file' accept='.jpg, .png' onChange={(e) => handleImageSelect(e, container.image_id)} hidden />
                            </label>
                            <img
                              src={deleteIcon}
                              alt='add color'
                              onClick={() =>
                                deleteImage(container.image_id, container.image_url).then(() => {
                                  fetchImageData()
                                  toast.success('Deleted Successfuly')
                                })
                              } // Add an onClick handler to log a message
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <AddSection text={'Add an Image'} onClick={handleImageSelect} />
        </div>
      </div>
    </div>
  )
}

export default MyImages
