import React, { useEffect, useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import Layout from 'view/layout/Header'

const RegisterUsername = () => {
  const checkLocalUser = () => {
    const check = JSON.parse(localStorage.getItem('username'))
    if (check) {
      navigate('/dashboard')
    }
  }
  useEffect(() => {
    checkLocalUser()
    document.title = 'Register'
  }, [])

  let navigate = useNavigate()
  //Form Process
  const [formData, setFormData] = useState({
    providedUsername: '',
    username: ''
  })

  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validationErrors = validateForm(formData)

    if (Object.keys(validationErrors).length === 0) {
      try {
        const apiUrl = process.env.REACT_APP_API_URL + '/api/register-username'
        const headers = {
          'Content-Type': 'application/json',
          'api-key': process.env.REACT_APP_API_KEY
        }

        await axios.post(apiUrl, formData, { headers: headers })

        setFormData({
          providedUsername: '',
          username: ''
        })
        setErrors({})
        toast.success('New Username Updated Successfully!')
        navigate(`/password-reset?step=2&providedUsername=${formData.providedUsername}&username=${formData.username}`)
      } catch (error) {
        console.error('Error:', error)
        toast.error('Error updating new username')
      }
    } else {
      setErrors(validationErrors)
    }
  }

  const validateForm = (data) => {
    const errors = {}

    if (!data.providedUsername.trim()) {
      errors.providedUsername = 'Provided Username is required'
    }

    if (!data.username.trim()) {
      errors.username = 'New Username is required'
    }

    return errors
  }

  return (
    <div className='container'>
      <Layout />
      <div className='container1'>
        <div className='container-left'>
          <h1 className='welcome'>Welcome</h1>
          <div className='first-line'>Thank you for registering with the 40 Knots AutonoME platform.</div>
          <p className='para'>
            If you’re on this page, you’ve spoken with one of our representatives and have been provided a temporary username and password.
          </p>
          <p className='para'>
            To begin, create a new, unique username and password. Once complete, you can begin exploring our pre made templates or start a
            new project from scratch.
          </p>
        </div>
        <div className='container-right'>
          <form className='user-form' onSubmit={handleSubmit}>
            <p className='heading2'>Create a unique user name</p>
            <div className='input-box'>
              <input
                type='text'
                name='providedUsername'
                placeholder='Provided username'
                value={formData.providedUsername}
                onChange={handleInputChange}
              />
              {errors.providedUsername && <span className='error-color'>{errors.providedUsername}</span>}
            </div>
            <div className='input-box'>
              <input type='text' name='username' placeholder='New username' value={formData.username} onChange={handleInputChange} />
              {errors.username && <span className='error-color'>{errors.username}</span>}
            </div>
            <button className='register-button' type='submit'>
              REGISTER NEW USERNAME
            </button>
          </form>
        </div>
        {/* Sign in form */}
        <div>
          <div className='container3'>
            <span className='terms'>
              <p>40 Knots™ 888 Prospect Street Suite 200 La Jolla, California 92037 USA</p>
              <p>
                <b>Terms of Use | Privacy Policy | Cookie Policy</b>
              </p>
              <p>© 2023 Corsaire Corporation™. All rights reserved.</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterUsername
