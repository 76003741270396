import React, { createContext, useState, useEffect } from 'react'

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [authenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    if (!authenticated) setIsAuthenticated(isAuthenticated())
  }, [])
  const isAuthenticated = () => {
    // TODO : This logic needs to be refactored to rely on API token data
    return JSON.parse(localStorage.getItem('token'))
  }
  return <AuthContext.Provider value={{ authenticated }}>{children}</AuthContext.Provider>
}

export { AuthProvider }
