import React, { useState, useEffect, useRef } from 'react'
import Layout from 'view/layout/Header'
import LeftSection from 'view/layout/Left-Section'
import PDFUploader from '../pdfuploader'
import ReferenceForm from '../refrenceform'
import Author from '../author'
import Claim from '../claim'
import { getUserIdFromLocalStorage } from 'config/apiConfig'
import { getPdfList } from 'helper/apiHelper'
import dropdwon from '../../../assets/images/my-reference/dropdown.svg'
import searchicon from '../../../assets/images/my-reference/Search.png'
import './index.scss'

const MyReference = () => {
  const [file, setFile] = useState(null)
  const [showAuthor, setShowAuthor] = useState(true)
  const [showClaim, setShowClaim] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [selectedOption, setSelectedOption] = useState('Author')
  const [pdfData, setPdfData] = useState({
    author_name: '',
    claim: '',
    publication_date: '',
    annotation: '',
    citation: ''
  })
  const user_id = getUserIdFromLocalStorage()
  const [listData, setListData] = useState([])
  const [searchWord, setSearchWord] = useState('')
  const [filteredData, setFilteredData] = useState([])

  const handleSuccessfulPdfUpload = (data) => {
    fetchDataFromS3(user_id)
    console.log(data, 'list')
  }

  const fileInputRef = useRef(null)

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clear the file input
    }
  }
  useEffect(() => {
    document.title = 'My References'
    if (user_id) {
      fetchDataFromS3(user_id)
    }
  }, [user_id])

  useEffect(() => {
    fetchDataFromS3(user_id)
  }, [user_id])

  const fetchDataFromS3 = (userId) => {
    getPdfList('files', userId, searchWord, selectedOption) // Pass searchvalue and searchby
      .then((data) => {
        setListData(data)
        filterData(data)
      })
      .catch((error) => {
        console.error('Error fetching references:', error)
      })
  }

  const toggleDropdown = () => {
    setIsActive(!isActive)
  }

  const selectDropdownItem = (item) => {
    setSelectedOption(item)
    if (item === 'Author') {
      setShowAuthor(true)
      setShowClaim(false)
    } else if (item === 'Claim') {
      setShowClaim(true)
      setShowAuthor(false)
    } else {
      setShowAuthor(false)
      setShowClaim(false)
    }
  }

  const filterData = (data) => {
    const lowerCaseSearchTerm = searchWord.toLowerCase()
    const filtered = data.filter((item) => {
      const lowerCaseData = {
        file_name: item.file_name ? item.file_name?.toLowerCase() : '',
        author_name: item.author_name?.toLowerCase(),
        claim: item.claim?.toLowerCase(),
        publication_date: item.publication_date?.toLowerCase(),
        annotation: item.annotation?.toLowerCase(),
        citation: item.citation?.toLowerCase()
      }
      return Object.values(lowerCaseData).some((field) => field?.includes(lowerCaseSearchTerm))
    })

    setFilteredData(filtered)
  }
  const originalData = listData?.slice()

  const handleSearchChange = (e) => {
    const searchValue = e.target.value
    setSearchWord(searchValue)

    if (searchValue === '') {
      // Use the original data when the search input is empty
      setFilteredData(originalData)
    } else {
      filterData(listData)
    }
  }

  return (
    <div className='container-div'>
      <Layout />
      <div className='container-div2'>
        <LeftSection name='reference' />
        <div className='right-container palettes reference'>
          <div className='right-section-title'>My References</div>
          <div className='add-reference'>Add Reference</div>
          <PDFUploader setPdfData={setPdfData} setFile={setFile} file={file} fileInputRef={fileInputRef} listData={listData} />
          <ReferenceForm
            pdfData={pdfData}
            setPdfData={setPdfData}
            file={file}
            onSuccessfulUpload={handleSuccessfulPdfUpload}
            clearFileInput={clearFileInput}
            setFile={setFile}
          />
          {listData?.length > 0 ? (
            <div>
              <div className='create-new-color'>My Saved References</div>
              <div className='saved-reference'>
                <div className='dropdown'>
                  <div className='dropdown-button' onClick={toggleDropdown}>
                    {selectedOption}
                    <img src={dropdwon} alt='' />
                    {isActive && (
                      <div className='dropdown-content'>
                        <div className='dropdown-item' onClick={() => selectDropdownItem('Author')}>
                          Author
                        </div>
                        <div className='middle-line'></div>
                        <div className='dropdown-item' onClick={() => selectDropdownItem('Claim')}>
                          Claim
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='search-bar'>
                  <input type='text' placeholder='Search' value={searchWord} onChange={handleSearchChange} />
                  <img src={searchicon} alt='img' className='search-image' />
                </div>
              </div>
              {showAuthor && selectedOption === 'Author' && <Author listData={filteredData} />}
            </div>
          ) : (
            <div></div>
          )}

          {showClaim && selectedOption === 'Claim' && <Claim listData={filteredData} />}
        </div>
      </div>
    </div>
  )
}

export default MyReference
